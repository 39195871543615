<template>
  <div class="payment-add">
    <PageTitle
      icon="fa fa-arrow-circle-right fa-lg"
      main="Cadastro conta pagar"
    />
    <b-form class="acPayment-form-add">
      <input v-if="id" type="hidden" id="acPayment-id" v-model="acPayment.id" />
      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="Forma pagamento" label-for="document-type">
            <b-form-select
              id="document-type"
              v-model="acPayment.idDocumentType"
              :options="optionsDocument"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Categoria" label-for="acPayment-category">
            <b-form-select
              id="acPayment-category"
              v-model="acPayment.idCategory"
              :options="optionsCategory"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione a categoria --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Emissao" label-for="move-release">
            <b-form-datepicker
              id="move-release"
              placeholder="Selecione"
              v-model="acPayment.dateRelease"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Vencimento" label-for="move-due">
            <b-form-datepicker
              id="move-due"
              placeholder="Selecione"
              v-model="acPayment.dateDue"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Documento" label-for="acPayment-document">
            <TheMask
              id="acPayment-document"
              v-model="acPayment.document"
              mask="##########"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Valor" label-for="acPayment-value">
            <b-input-group prepend="R$">
              <Money
                id="acPayment-value"
                v-model="acPayment.valueNominal"
                class="form-control"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Descrição" label-for="acPayment-note">
            <b-form-input
              id="acPayment-note"
              v-model="acPayment.note"
              trim
              lazy-formatter
              :formatter="formatter"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
export default {
  name: "account-payment-add",
  props: ["id"],
  components: { PageTitle, Money, TheMask },
  data: function () {
    return {
      acPayment: {
        idDocumentType: null,
        idCategory: null,
      },
      optionsDocument: [],
      optionsCategory: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      const method = this.acPayment.id ? "put" : "post";
      const id = this.acPayment.id ? `/${this.acPayment.id}` : "";
      const url = `${baseApiUrl}/acPayment${id}`;
      axios[method](url, this.acPayment)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/acPayment");
        })
        .catch(showError);
    },
    reset() {
      this.acPayment = {
        idDocumentType: null,
      };
    },
    back() {
      this.$router.push("/acPayment");
    },
    getCategory() {
      const url = `${baseApiUrl}/categories-expense`;
      axios.get(url).then((res) => {
        this.optionsCategory = res.data.map((category) => {
          return {
            value: category.id,
            text: category.description,
          };
        });
      });
    },
    getDocument() {
      const url = `${baseApiUrl}/documentType`;
      axios.get(url).then((res) => {
        this.optionsDocument = res.data.map((documentType) => {
          return {
            value: documentType.id,
            text: documentType.description,
          };
        });
      });
    },
  },
  created() {
    this.getDocument();
    this.getCategory();
  },
  mounted() {
    if (this.$route.params.id) {
      this.acPayment.id = this.$route.params.id;
      const url = `${baseApiUrl}/acPayment/${this.acPayment.id}`;
      axios.get(url).then((res) => {
        this.$delete(res.data, "documentType");
        this.acPayment = res.data;
        this.acPayment.valueNominal = this.acPayment.valueNominal.replace(
          /\./g,
          ","
        );
      });
    }
  },
};
</script>

<style>
.acPayment-form-add {
  padding: 20px;
}
</style>