<template>
  <div class="target-add">
    <PageTitle icon="fa fa-building fa-lg" main="Novo acordo comercial" />
    <b-form class="target-form-add">
      <input v-if="id" type="hidden" id="target-id" v-model="target.id" />
      <b-row>
        <b-col md="4" sm="12">
          <b-form-group label="Descrição" label-for="target-description">
            <b-form-input
              id="target-description"
              v-model="target.description"
              trim
              autofocus
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Fornecedor" label-for="target-provider">
            <b-form-select v-model="target.idProvider" :options="options">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione --</b-form-select-option
                >
              </template></b-form-select
            >
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data inicio" label-for="initial-date">
            <b-form-datepicker
              id="initial-date"
              placeholder="Selecione"
              v-model="target.initialDate"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Data Fim" label-for="final-date">
            <b-form-datepicker
              id="final-date"
              placeholder="Selecione"
              v-model="target.finalDate"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="Forma de repasse" label-for="document-type">
            <b-form-select
              id="document-type"
              v-model="target.idDocumentType"
              :options="optionsDocument"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Tempo repasse" label-for="transfer-time">
            <b-form-select
              id="transfer-time"
              v-model="target.transferTime"
              :options="optionsTransfer"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Valor" label-for="provider-amount">
            <b-input-group prepend="R$">
              <Money
                id="provider-amount"
                class="form-control"
                v-model="target.amount"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Sustentação" label-for="provider-support">
            <b-input-group prepend="%">
              <Money
                id="provider-support"
                v-model="target.support"
                class="form-control"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Anual" label-for="provider-commission">
            <b-input-group prepend="%">
              <Money
                id="provider-commission"
                v-model="target.commission"
                class="form-control"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Marketing" label-for="provider-marketing">
            <b-input-group prepend="R$">
              <Money
                id="provider-marketing"
                v-model="target.marketing"
                class="form-control"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group label="Complemento" label-for="provider-note">
            <b-form-input
              id="provider-note"
              v-model="target.note"
              trim
              autocomplete="off"
              maxlength="100"
              lazy-formatter
              :formatter="formatter"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">{{
        target.amount > 0 ? "Repasse" : "Salvar"
      }}</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
    <ModalSpPercentage :target="target" />
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
import ModalSpPercentage from "./ModalSplitPercentage";
const maskprovider = (provider) => {
  return provider.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};
export default {
  name: "TargetAdd",
  props: ["id"],
  components: { PageTitle, Money, ModalSpPercentage },
  data: function () {
    return {
      target: {
        idProvider: null,
        idDocumentType: null,
        transferTime: null,
      },
      options: [{ value: null, text: "Por favor selecione a opção" }],
      optionsDocument: [],
      optionsTransfer: [
        { value: "M", text: "MENSAL" },
        { value: "T", text: "TRIMESTRAL" },
        { value: "S", text: "SEMESTRAL" },
        { value: "A", text: "ANUAL" },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    onlyNumber: (event) => {
      const key = event.keyCode;
      if (key != 8 && key != 9) {
        if ((key > 47 && key < 58) || (key > 95 && key < 106)) {
          return true;
        } else {
          event.preventDefault();
        }
      }
    },
    save() {
      if (this.target.amount > 0) {
        this.$bvModal.show("modal-sp-percentage");
      } else {
        const method = this.target.id ? "put" : "post";
        const id = this.target.id ? `/${this.target.id}` : "";
        const url = `${baseApiUrl}/target${id}`;
        axios[method](url, this.target)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.$router.push("/target");
          })
          .catch(showError);
      }
    },
    reset() {
      this.target = {};
    },
    back() {
      this.$router.push("/target");
    },
    getDocument() {
      const url = `${baseApiUrl}/documentType`;
      axios.get(url).then((res) => {
        this.optionsDocument = res.data.map((documentType) => {
          return {
            value: documentType.id,
            text: documentType.description,
          };
        });
      });
    },
  },
  created() {
    const url = `${baseApiUrl}/provider`;
    axios.get(url).then((res) => {
      this.options = res.data.providers.map((provider) => {
        provider.cnpj = maskprovider(provider.cnpj);
        return {
          value: provider.id,
          text: `${provider.name} - ${provider.cnpj}`,
        };
      });
    });
    this.getDocument();
  },
  mounted() {
    if (this.$route.params.id) {
      this.target.id = this.$route.params.id;
      const url = `${baseApiUrl}/target/${this.target.id}`;
      axios.get(url).then((res) => {
        this.target = res.data;
      });
    }
  },
};
</script>

<style>
.target-form-add {
  padding: 20px;
}
</style>