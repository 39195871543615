<template>
  <b-modal
    id="modal-dt-target"
    size="xl"
    title="Lançamentos na Meta"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="getDetail"
  >
    <b-table small striped :items="details" :fields="fields" sort-icon-left>
      <template #cell(returnAssociate)="data">
        {{returnAssociated(data.item)}}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  name: "ModalDtTarget",
  props: ["data"],
  data: function () {
    return {
      details: [],
      fields: [
        {
          key: "associated",
          label: "Associado",
        },
        {
          key: "revenues",
          label: "Faturamento",
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          key: "support",
          label: "%Sustentação",
          formatter: (value) => {
            return value.replace(".", ",");
          },
        },
        {
          key: "valueReturn",
          label: "Retorno rede",
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          key: "associatedReturn",
          label: "%Associado",
          formatter: (value) => {
            return value.replace(".", ",");
          },
        },
        {
          key: "returnAssociate",
          label: "Retorno associado",
        },
      ],
    };
  },
  methods: {
    getDetail() {
      const url = `${baseApiUrl}/monitoring/${this.data.id}`;
      axios
        .get(url, {
          params: {
            monthInitial: this.data.monthInitial,
            monthFinal: this.data.monthFinal,
            yearInitial: this.data.yearInitial,
            yearFinal: this.data.yearFinal,
          },
        })
        .then((res) => (this.details = res.data));
    },
    returnAssociated(value){
      const result = (value.valueReturn * (value.associatedReturn/100)).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
      return result
    }
  },
};
</script>

<style>
</style>