<template>
  <b-modal
    id="modal-sp-percentage"
    size="xl"
    title="Dividir percentual de meta"
    no-close-on-esc
    no-close-on-backdrop
    @show="getAssociated"
    @hidden="clearAssociated"
  >
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="success" @click="saveTarget()">
        Confirmar
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancelar
      </b-button>
    </template>
    <b-container fluid>
      <b-row>
        <b-col>Valor meta</b-col>
        <b-col>{{
          this.target.amount.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</b-col>
        <b-col>Valor repassado</b-col>
        <b-col
          :style="this.getPercentualTotal != 100 ? 'color:red' : 'color:green'"
          >{{
            getRepasseTotal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</b-col
        >
      </b-row>
      <hr />
      <b-row>
        <b-col>Nome</b-col>
        <b-col cols="3">Percent(%)</b-col>
        <b-col cols="3">Valor</b-col>
      </b-row>
      <div v-for="companie of companies" :key="companie.idCompany">
        <b-row class="mb-2">
          <b-col>{{ companie.name }}</b-col>
          <b-col cols="3"
            ><Money
              v-model="companie.percentage"
              v-bind="money"
              size="sm"
              type="text"
              class="input-percentage"
          /></b-col>
          <b-col cols="3">{{
            getRepasseCompany(companie.percentage).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</b-col>
        </b-row>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
export default {
  name: "ModalSpPercentage",
  components: { Money },
  props: ["target"],
  data: function () {
    return {
      companies: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    getAssociated() {
      if (!this.target.id) {
        this.repositoryGetCompany();
      } else {
        const url = `${baseApiUrl}/targetCompany/${this.target.id}`;
        axios.get(url).then((res) => {
          if (res.data.length == 0) {
            this.repositoryGetCompany();
          } else {
            this.companies = res.data;
          }
        });
      }
    },
    repositoryGetCompany() {
      const url = `${baseApiUrl}/company`;
      axios.get(url).then((res) => {
        this.companies = res.data.companies.map((company) => ({
          idCompany: company.id,
          name: company.name,
          percentage: (0.0).toLocaleString("pt-BR", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        }));
      });
    },
    clearAssociated() {
      this.companies = [];
      delete this.target.companies;
    },
    getRepasseCompany(value) {
      if (this.companies.length > 0) {
        const result = this.target.amount * (value / 100);
        return result;
      }
      return 0;
    },
    saveTarget() {
      if (this.getPercentualTotal == 100) {
        this.target.companies = this.companies;
        const method = this.target.id ? "put" : "post";
        const id = this.target.id ? `/${this.target.id}` : "";
        const url = `${baseApiUrl}/target${id}`;
        axios[method](url, this.target)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.$router.push("/target");
          })
          .catch(showError);
      } else {
        showError("Não foi repassado os 100% da meta aos associados");
      }
    },
  },
  computed: {
    getPercentualTotal() {
      if (this.companies.length > 0) {
        const result = this.companies
          .map((res) => res.percentage)
          .reduce(function (acumulador, atual) {
            return parseFloat(acumulador) + parseFloat(atual);
          });
        return result;
      }
      return 0;
    },
    getRepasseTotal() {
      if (this.companies.length > 0) {
        const result = this.target.amount * (this.getPercentualTotal / 100);
        return result;
      }
      return 0;
    },
  },
};
</script>

<style>
.input-percentage {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>