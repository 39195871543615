<template>
  <div>
    <span class="vw-result">{{ statement.description }}</span>
    <b-row> </b-row>
    <b-row>
      <b-col cols="2"
        ><span class="vw-result"
          >Saldo Inicial:
          {{
            parseFloat(statement.previousBalance || 0).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</span
        ></b-col
      >
    </b-row>
    <b-table
      small
      hover
      striped
      :items="statement.launchs"
      :fields="fields"
      sort-icon-left
    >
      <template #cell(value)="data">
        <span
          class="positive"
          :class="{ negative: parseFloat(data.item.value) < 0 }"
          >{{ data.value }}</span
        >
      </template></b-table
    >
    <b-row>
      <b-col cols="2"
        ><span class="vw-result"
          >Saldo Final:
          {{
            parseFloat(getReturnResumo).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</span
        ></b-col
      >
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "StatementDetail",
  props: ["statement"],
  data: () => ({
    fields: [
      { key: "dateMove", label: "Movimento", formatter: "formatDate" },
      { key: "description", label: "Descrição" },
      {
        key: "value",
        label: "Valor",
        sortable: true,
        formatter: (value) => {
          return parseFloat(value).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
    ],
  }),
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    getReturnResumo() {
      const result = this.statement.launchs
        .map((res) => res.value)
        .reduce(function (acumulador, atual) {
          return parseFloat(acumulador) + parseFloat(atual);
        });
      return (
        parseFloat(this.statement.previousBalance) + parseFloat(result)
      ).toFixed(2);
    },
  },
};
</script>

<style>
</style>