<template>
  <div class="confirmed">
    <PageTitle
      icon="fa fa-check-circle fa-lg"
      main="Lançamentos Confirmados"
      path="/confirmedAdd"
      exPath="/confirmedPurchase"
      exLabel="Confirmar"
    />
    <div>
      <b-row>
        <b-col md="4" sm="12" class="my-1">
          <b-form-group
            label="Pesquisa"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escreva para pesquisar"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" class="my-1">
          <b-form-group
            label="Periodo"
            label-for="date-inicial"
            label-cols-sm="3"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-datepicker
              id="date-inicial"
              placeholder="Data inicial"
              v-model="periodInitial"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" class="my-1">
          <b-form-group
            label="à"
            label-for="date-final"
            label-cols-sm="3"
            label-align-sm="center"
            class="mb-0"
          >
            <b-form-datepicker
              id="date-final"
              placeholder="Data final"
              v-model="periodFinal"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-button variant="primary" @click="loadSearch">Pesquisar</b-button>
        </b-col>
      </b-row>
    </div>
    <b-table
      id="table-confirmed"
      small
      hover
      striped
      :items="confirmeds"
      :fields="fields"
      :current-page="page"
      :per-page="limit"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      empty-text="Não há registros correspondentes à sua solicitação"
      empty-filtered-text="Não há registros correspondentes à sua solicitação"
      sort-icon-left
    >
      <template #cell(reference)="data">
        {{ `${data.item.referenceMonth}/${data.item.referenceYear}` }}
      </template>
      <template #cell(actions)="data">
        <b-button
          variant="info"
          size="sm"
          @click="edit(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-pencil"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="remove(data.item)"
          class="mr-2"
        >
          <i class="fa fa-trash"></i>
        </b-button>
      </template>
    </b-table>
    <b-row align-h="between">
      <b-col md="4" sm="6" class="my-1">
        <b-pagination
          class="my-1"
          v-if="count > limit"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          aria-controls="table-confirmed"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col md="3" sm="6" class="my-1">
        <b-form-group
          label="Por página:"
          label-for="per-page-select"
          label-cols-sm="8"
          label-align-sm="right"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="limit"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import moment from "moment";
export default {
  name: "confirmed",
  components: { PageTitle },
  data: function () {
    return {
      confirmeds: [],
      page: 1,
      limit: 10,
      count: 0,
      filter: null,
      pageOptions: [10, 20, 30],
      periodInitial: null,
      periodFinal: null,
      fields: [
        { key: "id", label: "Código", sortable: true },
        { key: "company", label: "Loja", sortable: true },
        { key: "target", label: "Meta", sortable: true },
        { key: "reference", label: "Mes Referencia" },
        {
          key: "value",
          label: "Valor",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          key: "dateMove",
          label: "Movimento",
          formatter: "formatDate",
          sortable: true,
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    loadConfirmeds() {
      const url = `${baseApiUrl}/confirmed`;
      axios
        .get(url, {
          params: {
            periodInitial: this.periodInitial,
            periodFinal: this.periodFinal,
          },
        })
        .then((res) => {
          this.confirmeds = res.data.confirmeds;
          this.count = res.data.count;
        });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    remove(confirmed) {
      if (
        confirm(`Tem certeza que deseja excluir a confirmação ${confirmed.id}?`)
      ) {
        axios
          .delete(`${baseApiUrl}/confirmed/${confirmed.id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadConfirmeds();
          })
          .catch(showError);
      }
    },
    edit(id) {
      this.$router.push(`/confirmedAdd/${id}`);
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length;
      this.page = 1;
    },
    loadSearch() {
      this.loadConfirmeds();
    },
  },
  mounted() {
    this.loadConfirmeds();
  },
};
</script>

<style>
</style>