import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatterDate, formatterValue } from '../utils/formatterData'
import { header, footer, pageA4Margins, marginFilter } from '../utils/template'


export function accountability(launchs, filters) {
   pdfMake.vfs = pdfFonts.pdfMake.vfs;
   const title = "Relatório Prestação de Contas";
   const release = (filters.launchInitial && filters.launchFinal) ? `${formatterDate(filters.launchInitial)} - ${formatterDate(filters.launchFinal)}` : '[TODOS]'
   const move = (filters.moveInitial && filters.moveFinal) ? `${formatterDate(filters.moveInitial)} - ${formatterDate(filters.moveFinal)}` : '[TODOS]'
   const infoFilters = {
      text: [
         "Filtros: ",
         `Lançamento: ${release}; `,
         `Movimento: ${move}; `,
      ],
      alignment: 'center',
      fontSize: 8,
      margin: marginFilter
   }
   const mesesAno = [
      "JANEIRO",
      "FEVEREIRO",
      "MARÇO",
      "ABRIL",
      "MAIO",
      "JUNHO",
      "JULHO",
      "AGOSTO",
      "SETEMBRO",
      "OUTUBRO",
      "NOVEMBRO",
      "DEZEMBRO",
   ]

   const mesesPresentes = [];
   const novoArray = [];

   // Percorre os objetos principais
   launchs.forEach(objetoPrincipal => {
      const { children } = objetoPrincipal;
      // Percorre os objetos filhos
      children.forEach(item => {
         const partesData = item.dateMove.split("-");
         const mes = parseInt(partesData[1]);

         if (!mesesPresentes.includes(mes)) {
            mesesPresentes.push(mes);
         }
      });
   });

   mesesPresentes.sort((a, b) => a - b)

   launchs.forEach(objetoPrincipal => {
      const { idMain, descriptionMain, children } = objetoPrincipal;

      const novoChildren = [];

      // Objeto para armazenar os valores por idCategory e mês
      const valoresPorIdCategoriaEMes = {};
      children.forEach(item => {
         const partesData = item.dateMove.split("-");
         const mes = parseInt(partesData[1]);
         const chave = `${item.idCategory}`;

         if (!valoresPorIdCategoriaEMes[chave]) {
            valoresPorIdCategoriaEMes[chave] = {
               idCategory: item.idCategory,
               typeOperation: item.typeOperation,
               arrayPorMes: {
                  [mes]: [parseFloat(item.value)]
               }
            };
         } else {
            if (!valoresPorIdCategoriaEMes[chave].arrayPorMes[mes]) {
               valoresPorIdCategoriaEMes[chave].arrayPorMes[mes] = [parseFloat(item.value)];
            } else {
               valoresPorIdCategoriaEMes[chave].arrayPorMes[mes].push(parseFloat(item.value));
            }
         }
      })


      Object.values(valoresPorIdCategoriaEMes).forEach(valorPorIdCategoriaEMes => {
         const { idCategory, typeOperation, arrayPorMes } = valorPorIdCategoriaEMes;

         const arrayPorMesFormatado = mesesPresentes.map(mes => ({
            [mes]: arrayPorMes[mes] ? arrayPorMes[mes].reduce((acc, curr) => acc + curr, 0) : 0
         }));

         novoChildren.push({
            category: children.find(child => child.idCategory === idCategory).category,
            idCategory,
            typeOperation,
            arrayPorMes: arrayPorMesFormatado
         });
      });

      novoArray.push({
         idMain,
         descriptionMain,
         children: novoChildren
      });
   });

   const totalMes = mesesPresentes.map((_, i) => novoArray.map(item => item.children.map(child => child.arrayPorMes).map(mes => Object.values(mes)).map(value => Object.values(value[i])).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))))
   for (let i = 0; i < totalMes.length; i++) {
      totalMes[i] = totalMes[i].map(total => total).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox));
   }
   const data = novoArray.map(item => {
      const valuesTotal = item.children.map(child => child.arrayPorMes).map(mes => Object.values(mes))
      const values = mesesPresentes.map((_, i) => valuesTotal.map(value => Object.values(value[i])).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox)))

      const children = item.children.map(child => {
         // const colorValue = child.typeOperation === 'C' ? '#00FF00' : '#FF0000'
         const values = child.arrayPorMes.map(objeto => Object.values(objeto)[0])
         return [{ text: child.category, fontSize: 8 }, ...values.map(val => ({ text: formatterValue(val), fontSize: 8, alignment: 'center' }))]
      })

      return [
         [{ text: item.descriptionMain, bold: true, fontSize: 9, margin: [0, 10, 0, 0] }, ...values.map(val => ({ text: formatterValue(val), alignment: 'center', margin: [0, 10, 0, 0], bold: true, fontSize: 9 }))],
         ...children,
      ]
   })

   const widths = ['*', ...mesesPresentes.map(() => 'auto')]

   const content = [
      infoFilters,
      {
         table: {
            widths: widths,
            body: [
               [{ text: 'DESCRIÇÃO', alignment: 'center', bold: true, fontSize: 9 }, ...mesesPresentes.map(mes => ({ text: mesesAno[mes - 1], bold: true, fontSize: 9, alignment: 'center' }))],
               ...(data.flat()),
               [{ text: 'SALDO', alignment: 'center', bold: true, fontSize: 9 }, ...totalMes.map(val => ({ text: formatterValue(val), bold: true, fontSize: 9 }))]
            ]
         }
      }
   ]
   const docDefinitions = {
      info: {
         title: title,
         author: 'Construmap',
      },
      pageSize: 'A4',
      pageOrientation: 'landscape',
      pageMargins: pageA4Margins,
      header: header(title),
      content: content,
      pageBreakBefore: function (currentNode, followingNodesOnPage) {
         return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
      },
      footer,
      styles: {
         header: {
            fontSize: 18,
            bold: true,
            alignment: 'right',
            margin: [0, 190, 0, 80]
         },
         content: {
            fontSize: 12
         },
      }
   }

   pdfMake.createPdf(docDefinitions).open();
}