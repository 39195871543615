<template>
  <div>
    <ul>
      <li v-for="m in menu" :key="m.label" class="menu-group">
        <a href="#" @click="toggleMenu(m)">
          <i :class="m.icon"></i><span>{{ m.label }}</span
          ><i class="fa fa-angle-down i-down"></i>
        </a>
        <div v-if="m.items.length > 0">
          <ul v-show="!m.hideMenuDropdown">
              <router-link v-for="item in m.items" :key="item.label" class="menu-item" :to="item.to" tag="li" active-class="active">
                <a><i :class="item.icon"></i><span>{{ item.label }}</span></a>
              </router-link>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuButton",
  props: ["icon", "label", "path", "itens"],
  methods: {
    toggleMenu(e) {
      e.hideMenuDropdown = !e.hideMenuDropdown;
    },
  },
  data: function () {
    return {
      teste: true,
      menu: this.itens
    };
  },
};
</script>

<style>
.menu-group {
  list-style-type: none;
  display: list-item;
}
.menu-group a {
  text-decoration: none;
  color: #495057;
  display: block;
  padding: 10px 15px;
}
.menu-group a:hover {
  background-color: #d20001;
  color: #ffffff;
  text-decoration: none;
}
.menu-group i {
  padding-right: 5px;
}
.menu-item i {
  padding-left: 15px;
}
.i-down {
  float: right;
  align-items: center;
  line-height: 22px;
}
.menu-group .active{
  background-color: #d20001;
}

.menu-group .active a{
  color: #fff;
}

</style>