import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/home/Home'
import Provider from '@/components/register/Provider'
import Proper from '@/components/register/Proper'
import ProviderAdd from '@/components/register/ProviderAdd'
import User from '@/components/register/User'
import UserAdd from '@/components/register/UserAdd'
import Associated from '@/components/register/Associated'
import AssociatedAdd from '@/components/register/AssociatedAdd'
import Company from '@/components/register/Company'
import CompanyAdd from '@/components/register/CompanyAdd'
import Monitoring from '@/components/target/Monitoring'
import Target from '@/components/target/Target'
import TargetAdd from '@/components/target/TargetAdd'
import Purchase from '@/components/target/Purchase'
import PurchaseAdd from '@/components/target/PurchaseAdd'
import Confirmed from '@/components/target/Confirmed'
import ConfirmedAdd from '@/components/target/ConfirmedAdd'
import AccountStatement from '@/components/financial/AccountStatement'
import BalanceCategory from '@/components/financial/BalanceCategory'
import AccountReceive from '@/components/financial/AccountReceive'
import AccountPayment from '@/components/financial/AccountPayment'
import AccountLaunch from '@/components/financial/AccountLaunch'
import AccountReceiveAdd from '@/components/financial/AccountReceiveAdd'
import AccountPaymentAdd from '@/components/financial/AccountPaymentAdd'
import AccountLaunchAdd from '@/components/financial/AccountLaunchAdd'
import CategorieFinance from '@/components/financial/CategorieFinance'
import Operation from '@/components/financial/Operation'
import OperationAdd from '@/components/financial/OperationAdd'
import ConfirmedPurchase from '@/components/target/ConfirmedPurchase'
import ReportPerformance from '@/components/report/ReportPerformance'
import ReportPerformanceProvider from '@/components/report/ReportPerformanceProvider'

import Auth from '@/components/auth/Auth'
import { userKey } from "@/global";

Vue.use(VueRouter)

const routes = [
   {
      name: 'auth',
      path: '/auth',
      component: Auth
   },
   {
      name: 'home',
      path: '/',
      component: Home
   },
   {
      name: 'proper',
      path: '/proper',
      component: Proper,
      meta: { requiresAdmin: true },
   },
   {
      name: 'provider',
      path: '/provider',
      component: Provider,
      meta: { requiresAdmin: true },
   },
   {
      name: 'user',
      path: '/user',
      component: User,
      meta: { requiresAdmin: true },
   },
   {
      name: 'company',
      path: '/company',
      component: Company,
      meta: { requiresAdmin: true },
   },
   {
      name: 'associated',
      path: '/associated',
      component: Associated,
      meta: { requiresAdmin: true },
   },
   {
      name: 'userAdd',
      path: '/userAdd',
      component: UserAdd,
      meta: { requiresAdmin: true },
   }, {
      name: 'userEdit',
      path: '/userAdd/:id',
      component: UserAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'companyAdd',
      path: '/companyAdd',
      component: CompanyAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'associatedAdd',
      path: '/associatedAdd',
      component: AssociatedAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'associatedEdit',
      path: '/associatedAdd/:id',
      component: AssociatedAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'companyEdit',
      path: '/companyAdd/:id',
      component: CompanyAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'providerAdd',
      path: '/providerAdd',
      component: ProviderAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'providerEdit',
      path: '/providerAdd/:id',
      component: ProviderAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'target',
      path: '/target',
      component: Target,
      meta: { requiresAdmin: true },
   },
   {
      name: 'targetAdd',
      path: '/targetAdd',
      component: TargetAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'targetEdit',
      path: '/targetAdd/:id',
      component: TargetAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'purchase',
      path: '/purchase',
      component: Purchase
   },
   {
      name: 'purchaseAdd',
      path: '/purchaseAdd',
      component: PurchaseAdd
   },
   {
      name: 'purchaseEdit',
      path: '/purchaseAdd/:id',
      component: PurchaseAdd,
      props: true
   },
   {
      name: 'confirmed',
      path: '/confirmed',
      component: Confirmed,
      meta: { requiresAdmin: true },
   },
   {
      name: 'confirmedAdd',
      path: '/confirmedAdd',
      component: ConfirmedAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'confirmedEdit',
      path: '/confirmedAdd/:id',
      component: ConfirmedAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'category-finance',
      path: '/category-finance',
      component: CategorieFinance,
      meta: { requiresAdmin: true },
   },
   {
      name: 'report-balance',
      path: '/reportBalance',
      component: BalanceCategory,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-receive',
      path: '/acReceive',
      component: AccountReceive,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-receive-add',
      path: '/acReceiveAdd',
      component: AccountReceiveAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-receive-edit',
      path: '/acReceiveAdd/:id',
      component: AccountReceiveAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-payment',
      path: '/acPayment',
      component: AccountPayment,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-payment-add',
      path: '/acPaymentAdd',
      component: AccountPaymentAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-payment-edit',
      path: '/acPaymentAdd/:id',
      component: AccountPaymentAdd,
      props: true,
      meta: { requiresAdmin: true },
   },
   {
      name: 'acStatement',
      path: '/acStatement',
      component: AccountStatement,
      meta: { requiresAdmin: true },
   },
   {
      name: 'monitoring',
      path: '/monitoring',
      component: Monitoring,
      meta: { requiresAdmin: true },
   },
   {
      name: 'acLaunch',
      path: '/acLaunch',
      component: AccountLaunch,
      meta: { requiresAdmin: true },
   },
   {
      name: 'account-launch-add',
      path: '/acLaunchAdd',
      component: AccountLaunchAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'operation',
      path: '/operation',
      component: Operation,
      meta: { requiresAdmin: true },
   },
   {
      name: 'confirmedPurchase',
      path: '/confirmedPurchase',
      component: ConfirmedPurchase,
      meta: { requiresAdmin: true },
   },
   {
      name: 'OperationAdd',
      path: '/operationAdd',
      component: OperationAdd,
      meta: { requiresAdmin: true },
   },
   {
      name: 'OperationEdit',
      path: '/operationAdd/:id',
      component: OperationAdd,
      props: true,
      meta: { requiresAdmin: true },
   },

   {
      name: 'ReportPerformance',
      path: '/reportPerformance',
      component: ReportPerformance,
      meta: { requiresAdmin: true },
   },
   {
      name: 'ReportPerformanceProvider',
      path: '/reportPerformanceProvider',
      component: ReportPerformanceProvider,
      meta: { requiresAdmin: true },
   },
]

const router = new VueRouter({
   mode: 'history',
   routes
})

router.beforeEach((to, from, next) => {
   const json = localStorage.getItem(userKey)
   if (to.matched.some(record => record.meta.requiresAdmin)) {
      const user = JSON.parse(json)
      user && user.role ? next() : next({ path: '/' })
   }
   else {
      next()
   }
})

export default router