<template>
  <div class="monitoring">
    <PageTitle icon="fa fa-desktop fa-lg" main="Monitoramento de metas" />
    <div class="form-filter">
      <b-form class="form-monitoring" inline>
        <label class="mr-sm-2" for="provider">Meta</label>
        <b-form-select
          id="provider"
          class="mb-2 mr-sm-2 mb-sm-0"
          :options="optionsTarget"
          v-model="monitoring.idTarget"
        >
          <template #first>
            <b-form-select-option :value="null"
              >-- TODOS --</b-form-select-option
            >
          </template></b-form-select
        >
        <label class="mr-sm-2" for="period">Periodo</label>
        <TheMask
          :mask="'##/####'"
          :masked="true"
          class="form-control mb-2 mr-sm-2 mb-sm-0"
          v-model="monitoring.dateInitial"
        ></TheMask>
        <label class="mr-sm-2" for="period">até</label>
        <TheMask
          :mask="'##/####'"
          :masked="true"
          class="form-control mb-2 mr-sm-2 mb-sm-0"
          v-model="monitoring.dateFinal"
        ></TheMask>
        <b-button variant="primary" @click="loadMonitoring">Pesquisar</b-button>
      </b-form>
    </div>
    <div class="monitoring-content">
      <div class="content-grid overflow-auto">
        <div v-if="monitoringResult.length > 0" class="description-content">
          <span>Descrição</span>
          <span>Meta</span>
          <span>Lançado</span>
          <span>Falta</span>
        </div>
        <div v-for="item of monitoringResult" :key="item.id">
          <MonitoringDetail
            @selectTarget="selectTarget"
            @selectBilling="selectBilling"
            :data="item"
          ></MonitoringDetail>
        </div>
      </div>
      <div class="resumo-grid">
        <b-card
          no-body
          header-border-variant="danger"
          border-variant="danger"
          header-text-variant="danger"
          header="RESUMO"
          bg-variant="light"
        >
          <b-container fluid>
            <b-row class="rowResumo">
              <b-col cols="6">Faturamento</b-col>
              <b-col cols="6" class="valueResumo">{{
                parseFloat(getRevenueResumo).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              }}</b-col>
            </b-row>
            <b-row class="rowResumo">
              <b-col cols="6">Percentual medio</b-col>
              <b-col cols="6" class="valueResumo"
                >{{ getMediaResumo.replace(".", ",") }} %</b-col
              >
            </b-row>
            <b-row class="rowResumo">
              <b-col cols="6">Retorno</b-col>
              <b-col cols="6" class="valueResumo">{{
                parseFloat(getReturnResumo).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              }}</b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>
    <ModalDtTarget :data="targetSelected"></ModalDtTarget>
    <ModalBgTarget @reloadPage="loadMonitoring" :data="billingSelected"></ModalBgTarget>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import MonitoringDetail from "./MonitoringDetail";
import ModalDtTarget from "./ModalDetailTarget";
import ModalBgTarget from "./ModalBillingTarget";
import axios from "axios";
import { baseApiUrl } from "@/global";
import moment from "moment";
import { TheMask } from "vue-the-mask";
export default {
  name: "monitoring",
  components: {
    PageTitle,
    TheMask,
    MonitoringDetail,
    ModalDtTarget,
    ModalBgTarget,
  },
  data: function () {
    return {
      monitoring: {
        idTarget: null,
        dateInitial: moment().format("01/YYYY"),
        dateFinal: moment().format("12/YYYY"),
      },
      optionsTarget: [],
      monitoringResult: [],
      targetSelected: {},
      billingSelected: {
        value: 0,
      },
    };
  },
  methods: {
    loadMonitoring() {
      const referenceInitial = this.formatteReference(
        this.monitoring.dateInitial
      );
      this.monitoring.monthInitial = referenceInitial[0];
      this.monitoring.yearInitial = referenceInitial[1];
      const referenceFinal = this.formatteReference(this.monitoring.dateFinal);
      this.monitoring.monthFinal = referenceFinal[0];
      this.monitoring.yearFinal = referenceFinal[1];
      const url = `${baseApiUrl}/testeBeta`;
      axios
        .get(url, {
          params: {
            idTarget: this.monitoring.idTarget,
            monthInitial: this.monitoring.monthInitial,
            monthFinal: this.monitoring.monthFinal,
            yearInitial: this.monitoring.yearInitial,
            yearFinal: this.monitoring.yearFinal,
          },
        })
        .then((res) => (this.monitoringResult = res.data.targets));
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatteReference(reference) {
      return reference.split("/");
    },
    selectTarget(id) {
      const referenceInitial = this.formatteReference(
        this.monitoring.dateInitial
      );
      this.targetSelected.monthInitial = referenceInitial[0];
      this.targetSelected.yearInitial = referenceInitial[1];
      const referenceFinal = this.formatteReference(this.monitoring.dateFinal);
      this.targetSelected.monthFinal = referenceFinal[0];
      this.targetSelected.yearFinal = referenceFinal[1];
      this.targetSelected.id = id;
    },
    selectBilling(target) {
      this.billingSelected.value = target.valueReturn;
      this.billingSelected.idTarget = target.idTarget;
      this.billingSelected.referenceMonth = target.referenceMonth;
      this.billingSelected.referenceYear = target.referenceYear;
    },
    getTarget() {
      const url = `${baseApiUrl}/target`;
      axios.get(url).then((res) => {
        this.optionsTarget = res.data.targets.map((target) => {
          return {
            value: target.id,
            text: target.description,
          };
        });
      });
    },
  },
  computed: {
    getRevenueResumo() {
      if (this.monitoringResult.length > 0) {
        const result = this.monitoringResult
          .map((res) => res.confirmed)
          .reduce(function (acumulador, atual) {
            return (parseFloat(acumulador) + parseFloat(atual)).toFixed(2);
          });
        return result;
      } else {
        return "0,00";
      }
    },
    getReturnResumo() {
      if (this.monitoringResult.length > 0) {
        const result = this.monitoringResult
          .map((res) => res.confirmed * (res.support / 100))
          .reduce(function (acumulador, atual) {
            return (parseFloat(acumulador) + parseFloat(atual)).toFixed(2);
          });
        return result;
      } else {
        return "0,00";
      }
    },
    getMediaResumo() {
      if (this.monitoringResult.length > 0) {
        const result = (
          (this.getReturnResumo / this.getRevenueResumo) *
          100
        ).toFixed(3);
        return result;
      } else {
        return "0,000";
      }
    },
  },
  mounted() {
    this.getTarget();
  },
};
</script>

<style>
.form-monitoring {
  padding: 5px;
  justify-content: center;
}
.monitoring-content {
  padding: 10px 0px;
  height: 75vh;
  display: grid;
  flex-direction: column;
  grid-template-rows: 1fr;
  grid-template-columns: 350px 1fr;
  grid-template-areas: "resumo-grid content-grid";
}
.resumo-grid {
  grid-area: resumo-grid;
  margin-right: 10px;
}
.content-grid {
  grid-area: content-grid;
}
.description-content {
  display: flex;
  justify-content: space-between;
  margin-right: 1.75rem;
  margin-left: 0.3rem;
}
.description-content span {
  flex: 1;
  font-weight: bold;
}
.valueResumo {
  text-align: end;
}
.rowResumo {
  padding: 3px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
</style>