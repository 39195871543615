<template>
  <div class="confirmedPurchase">
    <PageTitle
      icon="fa fa-check-circle fa-lg"
      main="Confirmar lançamentos associados"
    />
    <b-row>
      <b-col md="6" sm="12" class="my-1">
        <b-form-select
          v-model="company"
          @change="getPurchase"
          :options="optionsSelect"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- Selecione a loja --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-col>
      <b-col md="3" sm="12" class="my-1">
        <b-button
          :disabled="!confirms.length > 0"
          @click="confirmPurchase"
          variant="info"
          >Confirmar</b-button
        >
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      selectable
      selected-variant="danger"
      select-mode="multi"
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #head(selected)="">
        <span class="text-dark">&check;</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import moment from "moment";
export default {
  name: "confirmedPurchase",
  components: { PageTitle },
  data: () => {
    return {
      company: null,
      optionsSelect: [],
      fields: [
        { key: "selected", label: "&check;" },
        { key: "id", label: "Codigo" },
        { key: "target", label: "Meta", sortable: true },
        { key: "document", label: "Documento" },
        {
          key: "dateMove",
          label: "Lançamento",
          sortable: true,
          formatter: "formatDate",
        },
        {
          key: "value",
          label: "Valor",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
      ],
      items: [],
      confirms: [],
    };
  },
  methods: {
    selectPurchase() {
      const url = `${baseApiUrl}/queryConfirmedPurchase`;
      axios.get(url).then((res) => {
        this.optionsSelect = res.data.map((purchase) => {
          return {
            value: purchase.idCompany,
            text: `${purchase.company} (${purchase.count})`,
          };
        });
      });
    },
    getPurchase(value) {
      const url = `${baseApiUrl}/confirmedPurchase/${value}`;
      axios.get(url).then((res) => {
        this.items = res.data;
      });
    },
    formatteReference(reference) {
      return reference.split("/");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onRowSelected(items) {
      this.confirms = items;
    },
    confirmPurchase() {
      if (confirm("Deseja realmente vincular os lançamentos selecionados?")) {
        const result = this.confirms.map((confirm) => {
          const reference = this.formatteReference(
            moment(confirm.dateMove).format("MM/YYYY")
          );
          confirm.referenceMonth = reference[0];
          confirm.referenceYear = reference[1];
          return confirm;
        });
        const url = `${baseApiUrl}/queryConfirmedPurchase/`;
        axios
          .post(url, result)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.company = null;
            this.confirm = [];
            this.selectPurchase();
            this.getPurchase();
          })
          .catch(showError);
      }
    },
  },
  computed: {},
  mounted() {
    this.selectPurchase();
  },
};
</script>

<style>
</style>