<template>
  <b-modal
    id="modal-bg-target"
    title="Gerar conta a receber"
    no-close-on-esc
    no-close-on-backdrop
    centered
    @hidden="resetModal"
    @show="openModal"
  >
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="success" @click="generateAccount()">
        OK
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancelar
      </b-button>
    </template>
    <b-form-group label="Participante" label-for="acReceive-participant">
      <b-form-select
        id="acReceive-participant"
        v-model="receive.idParticipant"
        :options="optionsParticipant"
      />
    </b-form-group>
    <b-form-group label="Forma pagamento" label-for="document-type">
      <b-form-select
        id="document-type"
        v-model="receive.idDocumentType"
        :options="optionsDocument"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Selecione --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group label="Categoria" label-for="receive-category">
      <b-form-select
        id="receive-category"
        v-model="receive.idCategory"
        :options="optionsCategory"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Selecione a categoria --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group label="Documento" label-for="receive-document">
      <b-form-input
        id="receive-document"
        v-model="receive.document"
        trim
        maxlength="10"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Data emissão" label-for="date-release">
      <b-form-datepicker
        id="date-release"
        placeholder="Selecione"
        size="sm"
        required
        v-model="receive.dateRelease"
        class="mb-2"
        today-button
        label-today-button="Hoje"
        reset-button
        label-reset-button="Limpar"
        close-button
        label-close-button="Fechar"
        locale="pt-BR"
        :date-format-options="{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }"
      ></b-form-datepicker>
    </b-form-group>
    <b-form-group label="Data Vencimento" label-for="date-due">
      <b-form-datepicker
        id="date-due"
        placeholder="Selecione"
        size="sm"
        required
        v-model="receive.dateDue"
        class="mb-2"
        today-button
        label-today-button="Hoje"
        reset-button
        label-reset-button="Limpar"
        close-button
        label-close-button="Fechar"
        locale="pt-BR"
        :date-format-options="{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }"
      ></b-form-datepicker>
    </b-form-group>
    <b-form-group label="Descrição" label-for="receive-note">
      <b-form-input
        id="receive-note"
        v-model="receive.note"
        trim
        lazy-formatter
        :formatter="formatter"
        autocomplete="off"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Valor" label-for="acReceive-value">
      <b-form-input
        disabled
        :value="
          parseFloat(this.receive.valueNominal).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        "
      ></b-form-input>
    </b-form-group>
    {{ data }}
  </b-modal>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { VMoney } from "v-money";
import moment from "moment";
export default {
  name: "ModalBgTarget",
  props: ["data"],
  directives: { money: VMoney },
  data: function () {
    return {
      receive: {
        dateRelease: moment().format("YYYY-MM-DD"),
        idDocumentType: null,
        idCategory: null,
        note: `VERBA ${this.data.referenceMonth}/${this.data.referenceYear}`
      },
      target: {},
      optionsDocument: [],
      optionsCategory: [],
      optionsParticipant: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    openModal() {
      this.receive.valueNominal = this.data.value;
      this.receive.idTarget = this.data.idTarget;
      this.receive.referenceMonth = this.data.referenceMonth;
      this.receive.referenceYear = this.data.referenceYear;
      this.receive.note= `VERBA ${this.data.referenceMonth}/${this.data.referenceYear}`
      this.getProvider();
      this.getParticipant();
      this.getCategory();
    },
    resetModal() {
      this.receive = {
        dateRelease: moment().format("YYYY-MM-DD"),
        idDocumentType: null,
        idCategory: null,
        valueNominal: 0,
        idTarget: null,
        referenceMonth: null,
      };
    },
    getCategory() {
      const url = `${baseApiUrl}/categories-revenue`;
      axios.get(url).then((res) => {
        this.optionsCategory = res.data.map((category) => {
          return {
            value: category.id,
            text: category.description,
          };
        });
      });
    },
    generateAccount() {
      this.receive.type = "S";
      const url = `${baseApiUrl}/acReceive`;
      axios
        .post(url, this.receive)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$bvModal.hide("modal-bg-target");
          this.$emit("reloadPage");
        })
        .catch(showError);
    },
    getDocument() {
      const url = `${baseApiUrl}/documentType`;
      axios.get(url).then((res) => {
        this.optionsDocument = res.data.map((documentType) => {
          return {
            value: documentType.id,
            text: documentType.description,
          };
        });
      });
    },
    getParticipant() {
      const url = `${baseApiUrl}/participant`;
      axios.get(url).then((res) => {
        this.optionsParticipant = res.data.map((participant) => {
          return {
            value: participant.id,
            text: `${participant.name} - ${participant.cnpj}`,
          };
        });
      });
    },
    getProvider() {
      const url = `${baseApiUrl}/target/${this.receive.idTarget}`;
      axios.get(url).then((res) => {
        this.target = res.data;
        this.receive.idParticipant = res.data.idProvider;
      });
    },
  },
  computed: {
    valueNominal() {
      return parseFloat(this.receive.valueNominal).toLocaleString("pt-BR");
    },
  },
  mounted() {
    this.getDocument();
    
  },
};
</script>

<style>
</style>