<template>
  <div class="page-title">
    <div>
      <i v-if="icon" :class="icon"></i><span class="title-p">{{ main }}</span>
    </div>
    <div>
      <b-button v-if="path" variant="secondary" :to="path">Adicionar</b-button>
      <b-button v-if="exPath" variant="info" :to="exPath">{{ exLabel }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["icon", "main", "path", "exPath", "exLabel"],
};
</script>

<style>
.page-title {
  border-bottom: 1px solid #dadde0;
  min-height: 42px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-title i {
  padding-right: 5px;
}
.page-title a {
  margin-right: 5px;
}
.title-p{
   font-size: 1.2rem;
}
</style>