<template>
  <b-modal
    id="modal-vw-receive"
    size="xl"
    :title="main"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="getHistory"
    @hidden="reloaded"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Forma de pagamento">
            <b-form-input disabled :value="data.documentType"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Data Emissão">
            <b-form-input
              disabled
              :value="formatDate(data.dateRelease)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Data Vencimento">
            <b-form-input
              disabled
              :value="formatDate(data.dateDue)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Documento">
            <b-form-input disabled :value="data.document"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Valor">
            <b-form-input disabled :value="data.valueNominal"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="10">
          <b-form-group label="Descrição">
            <b-form-input disabled :value="data.note"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Situação">
            <b-form-input
              disabled
              :value="formatterStatus(data.status)"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card
            header-tag="header"
            no-body
            border-variant="secondary"
            bg-variant="light"
          >
            <template #header>
              <b-row align-h="between">
                <b-col cols="4"
                  ><h5 class="mb-0">Movimentações do Título</h5></b-col
                >
                <b-col cols="2">
                  <b-button
                    class="btn-reversal"
                    :disabled="data.status !== 'L'"
                    size="sm"
                    variant="primary"
                    @click="reversal()"
                  >
                    <i class="fa fa-undo"></i>
                    Estornar
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-table
              small
              striped
              :items="history"
              :fields="fields"
              sort-icon-left
            >
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
export default {
  name: "ModalVwReceive",
  props: ["main", "data", "pathHistory", "pathReversal"],
  data: function () {
    return {
      history: [],
      fields: [
        {
          key: "type",
          label: "Operação",
          formatter: (value) => {
            if (value === "I") {
              return "INCLUSÃO";
            } else if (value === "C") {
              return "CANCELADO";
            } else if (value === "A") {
              return "ALTERAÇÃO";
            } else if (value === "L") {
              return "LIQUIDADO";
            } else if (value === "E") {
              return "ESTORNO";
            }
          },
        },
        {
          key: "date",
          label: "Data",
          formatter: (value) => {
            return moment(value).format("DD/MM/YYYY");
          },
        },
        {
          key: "valueAdd",
          label: "Acrescimo",
          formatter: (value) => {
            return value.replace(".", ",");
          },
        },
        {
          key: "valueDiscount",
          label: "Desconto",
          formatter: (value) => {
            return value.replace(".", ",");
          },
        },
        {
          key: "valueFinal",
          label: "Valor",
          formatter: (value) => {
            return value.replace(".", ",");
          },
        },
        { key: "idUser", label: "Usuario" },
      ],
    };
  },
  methods: {
    formatterStatus(value) {
      if (value === "C") {
        return "CANCELADO";
      } else if (value === "L") {
        return "LIQUIDADO";
      } else {
        return "ABERTO";
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getHistory() {
      const url = `${baseApiUrl}/${this.pathHistory}/${this.data.id}`;
      axios.get(url).then((res) => {
        this.history = res.data;
      });
    },
    reversal() {
      const url = `${baseApiUrl}/${this.pathReversal}/${this.data.id}`;
      if (confirm(`Tem certeza que deseja estornar o pagamento?`)) {
        axios
          .post(url, {
            valuePaid: this.data.valuePaid,
          })
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.getHistory();
            this.$emit("reloadModal", this.data.id);
          })
          .catch(showError);
      }
    },
    reloaded() {
      this.$emit("reloadPage");
    },
  },
};
</script>

<style>
.btn-reversal {
  position: absolute;
  right: 0;
}
</style>