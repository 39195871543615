<template>
  <div>
    <PageTitle
      icon="fa fa fa-bank fa-lg"
      main="Balancete"
    ></PageTitle>
    <div class="form-filter">
      <b-form class="form-filter">
        <b-row>
          <b-col md="2" sm="12">
            <b-form-group label="Lançamento" label-for="launch-initial">
              <b-form-datepicker
                id="launch-initial"
                placeholder="Data inicial"
                v-model="filter.launchInitial"
                today-button
                label-today-button="Hoje"
                reset-button
                label-reset-button="Limpar"
                close-button
                label-close-button="Fechar"
                locale="pt-BR"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12">
            <b-form-group :label="`\u00A0`" label-for="launch-final">
              <b-form-datepicker
                id="launch-final"
                placeholder="Data final"
                v-model="filter.launchFinal"
                :min="filter.launchInitial"
                today-button
                label-today-button="Hoje"
                reset-button
                label-reset-button="Limpar"
                close-button
                label-close-button="Fechar"
                locale="pt-BR"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12">
            <b-form-group label="Movimento" label-for="move-initial">
              <b-form-datepicker
                id="move-initial"
                placeholder="Data inicial"
                v-model="filter.moveInitial"
                today-button
                label-today-button="Hoje"
                reset-button
                label-reset-button="Limpar"
                close-button
                label-close-button="Fechar"
                locale="pt-BR"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="2" sm="12">
            <b-form-group :label="`\u00A0`" label-for="move-final">
              <b-form-datepicker
                id="move-final"
                placeholder="Data final"
                v-model="filter.moveFinal"
                :min="filter.moveInitial"
                today-button
                label-today-button="Hoje"
                reset-button
                label-reset-button="Limpar"
                close-button
                label-close-button="Fechar"
                locale="pt-BR"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="12">
            <b-form-group :label="`\u00A0`">
              <b-button variant="primary" @click="getStatement"
                >Pesquisar</b-button
              >
              <b-button
                class="ml-2"
                :disabled="isfiltering"
                variant="secondary"
                @click="generatePdf"
                >Gerar PDF</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-row v-if="this.balance.length === 0">
      <div class="warning-empty">
        <span>Nenhum registro para mostrar</span>
      </div>
    </b-row>
    <div v-for="item in balance" :key="item.id">
      <BalanceDetail
        :balance="item"
        class="detail"
        v-if="item.children.length > 0"
      />
      <hr v-if="item.children.length > 0" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import PageTitle from "../template/PageTitle";
import moment from "moment";
import { accountability } from "../report/financialReport/accountability";
import BalanceDetail from "./BalanceDetail";
export default {
  name: "balanceCategory",
  components: { PageTitle, BalanceDetail },
  data: function () {
    return {
      balance: [],
      valueInitial: 0,
      isfiltering: false,
      filter: {
        launchInitial: moment().format("YYYY-MM-DD"),
        launchFinal: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    getStatement() {
      const url = `${baseApiUrl}/balance-category`;
      axios
        .get(url, {
          params: {
            launchInitial: this.filter.launchInitial,
            launchFinal: this.filter.launchFinal,
            moveInitial: this.filter.moveInitial,
            moveFinal: this.filter.moveFinal,
          },
        })
        .then((res) => {
          this.balance = res.data;
          this.isfiltering = false;
        })
        .catch(showError);
    },
    generatePdf() {
      if (this.balance.length <= 0) {
        showError("Não há registros correspondentes à sua solicitação");
        return;
      }
      accountability(this.balance, this.filter);
    },
    setState() {
      this.balance;
    },
  },
  computed: {
    getReturnResumo() {
      if (this.balance.length > 0) {
        const result = this.balance
          .map((res) => res.value)
          .reduce(function (acumulador, atual) {
            return parseFloat(acumulador) + parseFloat(atual);
          });
        return (parseFloat(this.valueInitial) + parseFloat(result)).toFixed(2);
      } else {
        return "0,00";
      }
    },
  },
  watch: {
    filter: {
      handler() {
        this.isfiltering = true;
      },
      deep: true,
    },
  },
  mounted() {
    // this.getStatement();
  },
};
</script>

<style>
.positive {
  color: green;
}
.negative {
  color: red;
}
.form-filter {
  padding: 5px;
}
.vw-result {
  font-weight: bold;
}
.warning-empty {
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
}
.detail {
  margin-top: 1.5rem;
}
</style>