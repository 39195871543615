<template>
  <div class="confirmed-add">
    <PageTitle icon="fa fa-bar-chart fa-lg" main="Lançamento Rede" />
    <b-form class="confirmed-form-add">
      <input v-if="id" type="hidden" id="confirmed-id" v-model="confirmed.id" />
      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="Meta" label-for="confirmed-target">
            <b-form-select
              :disabled="mode === 'view'"
              id="confirmed-target"
              v-model="confirmed.idTarget"
              :options="optionsTarget"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione a Meta --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Empresa" label-for="confirmed-Company">
            <b-form-select
              :disabled="mode === 'view'"
              id="confirmed-Company"
              v-model="confirmed.idCompany"
              :options="optionsCompany"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione o Associado --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Mês referencia" label-for="reference-date">
            <TheMask
              :readonly="mode === 'view'"
              id="reference-date"
              v-model="confirmed.referenceDate"
              mask="##/####"
              :masked="true"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Documento" label-for="confirmed-document">
            <TheMask
              :readonly="mode === 'view'"
              id="confirmed-document"
              v-model="confirmed.document"
              mask="##########"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Valor" label-for="confirmed-value">
            <b-input-group prepend="R$">
              <Money
                :readonly="mode === 'view'"
                id="confirmed-value"
                class="form-control"
                v-model="confirmed.value"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save"
        >Salvar</b-button
      >
      <b-button v-if="!id" @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
export default {
  name: "confirmedAdd",
  props: ["id"],
  components: { PageTitle, Money, TheMask },
  data: function () {
    return {
      confirmed: {
        idTarget: null,
        idCompany: null,
      },
      optionsTarget: [],
      optionsCompany: [],
      mode: null,
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    formatteReference(reference) {
      return reference.split("/");
    },
    save() {
      const method = this.confirmed.id ? "put" : "post";
      const id = this.confirmed.id ? `/${this.confirmed.id}` : "";
      const reference = this.formatteReference(this.confirmed.referenceDate);
      this.confirmed.referenceMonth = reference[0];
      this.confirmed.referenceYear = reference[1];
      const url = `${baseApiUrl}/confirmed${id}`;
      axios[method](url, this.confirmed)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/confirmed");
        })
        .catch(showError);
    },
    reset() {
      this.confirmed = {};
    },
    back() {
      this.$router.push("/confirmed");
    },
    getTarget() {
      const url = `${baseApiUrl}/target`;
      axios.get(url).then((res) => {
        this.optionsTarget = res.data.targets.map((target) => {
          return {
            value: target.id,
            text: target.description,
          };
        });
      });
    },
    getAssociated() {
      const url = `${baseApiUrl}/company`;
      axios.get(url).then((res) => {
        this.optionsCompany = res.data.companies.map((company) => ({
          value: company.id,
          text: company.name,
        }));
      });
    },
  },
  created() {
    this.getTarget();
    this.getAssociated();
  },
  mounted() {
    if (this.$route.params.id) {
      this.confirmed.id = this.$route.params.id;
      // this.mode = "view";
      const url = `${baseApiUrl}/confirmed/${this.confirmed.id}`;
      axios.get(url).then((res) => {
        this.confirmed = res.data;
        this.confirmed.value = this.confirmed.value.replace(/\./g, ",");
      });
    }
  },
};
</script>

<style>
.confirmed-form-add {
  padding: 20px;
}
</style>