<template>
  <div>
    <PageTitle icon="fa fa-id-card fa-lg" main="Cadastro Associado" />
    <b-form class="associated-form-add">
      <input
        v-if="id"
        type="hidden"
        id="associated-id"
        v-model="associated.id"
      />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Descrição" label-for="associated-description">
            <b-form-input
              id="associated-description"
              v-model="associated.description"
              :formatter="formatter"
              trim
              autofocus
              autocomplete="off"
              :state="validateState('description')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Categoria" label-for="associated-category">
            <b-form-select
              v-model="associated.category"
              :options="options"
              :state="validateState('category')"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group
            label="Retorno Financeiro"
            label-for="associated-return"
          >
            <Money
              id="associated-return"
              v-model="associated.return"
              class="form-control"
              v-bind="money"
              :class="{ 'is-invalid': validateState('return') === false }"
            ></Money>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button variant="secondary" class="mr-2" @click="reset"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  name: "AssociatedAdd",
  props: ["id"],
  components: { PageTitle, Money },
  data: function () {
    return {
      associated: {},
      options: [
        { value: "E", text: "EFETIVO" },
        { value: "I", text: "INICIANTE" },
      ],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  validations: {
    associated: {
      description: {
        required,
      },
      category: {
        required,
      },
      return: {
        required,
        minValue: minValue(0.01),
      },
    },
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      this.$v.associated.$touch();
      if (!this.$v.associated.$invalid) {
        const method = this.associated.id ? "put" : "post";
        const id = this.associated.id ? `/${this.associated.id}` : "";
        const url = `${baseApiUrl}/associated${id}`;
        axios[method](url, this.associated)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.$router.push("/associated");
          })
          .catch(showError);
      }
    },
    back() {
      this.$router.push("/associated");
    },
    reset() {
      this.associated = {};
      this.$v.associated.$reset();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.associated[name];
      return $dirty && $error ? !$error : null;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.associated.id = this.$route.params.id;
      const url = `${baseApiUrl}/associated/${this.associated.id}`;
      axios.get(url).then((res) => {
        this.associated = res.data;
      });
    }
  },
};
</script>

<style>
.associated-form-add {
  padding: 20px;
}
</style>