<template>
  <div class="categorie">
   <b-card
      header-tag="header"
      no-body
      border-variant="secondary"
      bg-variant="light"
      style="height: calc(100vh - 150px);"
   >
      <template #header>
         <b-row align-h="between">
            <b-col cols="4"
            ><h5 class="mb-0">Categoria Financeira</h5></b-col
            >
            <b-col cols="2">
            <b-button
               class="btn-reversal"
               size="sm"
               variant="primary"
               @click="openModal()"
            >
               <i class="fa fa-plus"></i>
               Adicionar
            </b-button>
            </b-col>
         </b-row>
      </template>
      <b-list-group flush>
         <b-list-group-item class="category-item" v-for="category in categories" :key="category.id">
            <div class="d-flex justify-content-between align-items-center">
               <span><i class="fa icon-button" :class="[category.expanded ? 'fa-angle-down': 'fa-angle-right']" @click="showChildren(category.id)">
               </i> {{ category.description }}</span>
               <b-button variant="danger" size="sm" @click="remove(category.id)">  
                     <i class="fa fa-times"></i>
                  </b-button>
            </div>
            <b-list-group  v-show="category.expanded" flush>
               <b-list-group-item class="category-item d-flex justify-content-between align-items-center" v-for="subcategory in category.children" :key="subcategory.id">
                   <span> <i :class="{
                     'fa fa-plus positive': (subcategory.category == 'R' && subcategory.type =='A'), 
                     'fa fa-minus negative': (subcategory.category == 'D' && subcategory.type =='A')
                  }">
                  </i>
                  {{ subcategory.description }}</span>
                  <b-button class="mr-3" variant="danger" size="sm" @click="remove(subcategory.id)">  
                     <i class="fa fa-times"></i>
                  </b-button>
               </b-list-group-item>
            </b-list-group>
         </b-list-group-item>
      </b-list-group>
   </b-card>
   <ModalCategorieAdd @reloadPage="getCategories"/>
  </div>
</template>

<script>
import ModalCategorieAdd from "./ModalCategorieAdd";
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
   name: "CategorieFinance",
   components:{ ModalCategorieAdd },
   data: function () {
      return {
         categories: []
      }
   },
   methods:{
      openModal(){
         this.$bvModal.show("modal-category");
      },
      getCategories() {
         const url = `${baseApiUrl}/category-finance`;
         axios.get(url).then((res) => this.categories = res.data);
      },
      remove(id) {
         if (confirm(`Tem certeza que deseja cancelar a categoria?`)) {
        axios
          .delete(`${baseApiUrl}/category-finance/${id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.getCategories();
          })
      }
      },
      showChildren(id){
         const index = this.categories.findIndex(category => category.id == id)
         this.categories[index].expanded = !this.categories[index].expanded
         if (this.categories[index].children.length <=0 && this.categories[index].expanded) {
            this.getChildren(id)
         }
      },
      getChildren(id){
         const url = `${baseApiUrl}/category-finance/${id}`;
         axios.get(url).then((res) => {
            const index = this.categories.findIndex(category => category.id == id)
            this.categories[index].children = res.data
         });
      }
   },
  mounted() {
    this.getCategories();
  },
}
</script>

<style>
.category-item{
   padding: 0.25rem 1.25rem;
}
.category-item :hover{
   background-color: #ddd;
   cursor: pointer;
}
.icon-button{
   cursor: pointer;
}
.positive{
   color: green;
}
.negative {
   color: red
}
</style>