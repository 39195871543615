import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatterValue, formatterDate } from '../utils/formatterData'
import { footer, pageA4Margins, marginFilter } from '../utils/template'

export function accountStatement(statements, filters) {
   pdfMake.vfs = pdfFonts.pdfMake.vfs;
   pdfMake.tableLayouts = {
      myZebraLayout: {
         hLineWidth() {
            return 0;
         },
         vLineWidth() {
            return 0;
         },
         paddingLeft(i) {
            return i && 4 || 0;
         },
         paddingRight(i, node) {
            return (i < node.table.widths.length - 1) ? 4 : 0;
         },
         fillColor(rowIndex) {
            return (rowIndex % 2 === 0) ? '#BBBBBB' : '#DDDDDD';
         }
      },
      myNoBordersLayout: {
         hLineWidth(i, node) {
            if (i === 0 || i === node.table.body.length) {
               return 0;
            }
            return (i === node.table.headerRows) ? 2 : 0;
         },
         vLineWidth() {
            return 0;
         },
         paddingLeft(i) {
            return i === 0 ? 0 : 8;
         },
         paddingRight(i, node) {
            return (i === node.table.widths.length - 1) ? 0 : 8;
         },
         paddingBottom: function (i, node) {
            if (i === (node.table.headerRows - 1))
               return 0
            return 10;
         },
      }
   };
   const title = "Extrato conta corrente";

   const release = (filters.launchInitial && filters.launchFinal) ? `${formatterDate(filters.launchInitial)} - ${formatterDate(filters.launchFinal)}` : '[TODOS]'
   const move = (filters.moveInitial && filters.moveFinal) ? `${formatterDate(filters.moveInitial)} - ${formatterDate(filters.moveFinal)}` : '[TODOS]'
   const infoFilters = {
      text: [
         "Filtros: ",
         `Lançamento: ${release}; `,
         `Movimento: ${move}; `,
      ],
      alignment: 'center',
      fontSize: 8,
      margin: marginFilter
   }

   let dataFinal = [];

   for (let i = 0; i < statements.length; i++) {
      if (statements[i].launchs.length > 0) {
         const valueCredit = statements[i].launchs.map(acc => {
            if (acc.value && acc.value > 0)
               return acc.value
            return 0;
         }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))

         const valueDebit = statements[i].launchs.map(acc => {
            if (acc.value && acc.value < 0)
               return acc.value
            return 0;
         }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))

         const element = [
            { text: statements[i].description, alignment: 'center', margin: [0, 20, 0, 5], bold: true, fontSize: 14 },
            {
               table: {
                  widths: ['*', 'auto'],
                  body: [
                     [
                        { text: "Saldo Inicial", margin: [10, 0], bold: true },
                        { text: formatterValue(statements[i].previousBalance), margin: [10, 0], bold: true },
                     ],
                  ],
               },
               layout: 'myZebraLayout',
               style: 'zebraStyle'
            },
            {
               margin: [0, 10],
               table: {
                  headerRows: 1,
                  widths: ['*', 'auto', 'auto', 'auto'],
                  body: [
                     [{ text: 'Descrição', bold: true }, { text: 'Emissão', bold: true }, { text: 'Movimento', bold: true }, { text: 'Valor', bold: true }],
                     ...statements[i].launchs.map(launch => {
                        const valueNominal = formatterValue(launch.value);
                        const dateRelease = formatterDate(launch.dateRelease);
                        const dateMove = formatterDate(launch.dateMove);
                        const colorValue = launch.value > 0 ? '#00FF00' : '#FF0000'
                        return [launch.description, dateRelease, dateMove, { text: valueNominal, color: colorValue }]
                     }),
                  ],
               },
               layout: 'myNoBordersLayout',
               style: 'noBordersStyle'
            },
            {
               table: {
                  widths: ['*', 'auto'],
                  body: [
                     [
                        { text: "Saldo Final", margin: [10, 0], bold: true },
                        { text: formatterValue(parseFloat(statements[i].previousBalance) + valueCredit + valueDebit), margin: [10, 0], bold: true },
                     ],
                  ],
               },
               layout: 'myZebraLayout',
               style: 'zebraStyle'
            },
            { text: `Resumo Extrato ${statements[i].description}`, alignment: 'center', margin: [0, 20, 0, 5], bold: true, fontSize: 14 },
            {
               table: {
                  widths: ['*', 'auto'],
                  body: [
                     [
                        { text: "Credito (+)", margin: [10, 0], bold: true },
                        { text: formatterValue(valueCredit), margin: [10, 0], bold: true },
                     ],
                     [
                        { text: "Debito (-)", margin: [10, 0], bold: true },
                        { text: formatterValue(valueDebit), margin: [10, 0], bold: true },
                     ],
                     [
                        { text: "Valor Total", margin: [10, 0], bold: true },
                        { text: formatterValue(valueCredit + valueDebit), margin: [10, 0], bold: true },
                     ],
                  ],
               },
               layout: 'myZebraLayout',
               style: 'zebraStyle'
            },
         ]

         dataFinal.push(element)
      }

   }

   const content = [
      infoFilters,
      ...dataFinal
   ];

   const docDefinitions = {
      info: {
         title: title,
         author: 'Construmap',
      },
      pageSize: 'A4',
      pageMargins: pageA4Margins,
      header: function (currentPage) {
         if (currentPage == 1)
            return {
               text: title,
               fontSize: 14,
               alignment: 'center',
               bold: true,
               margin: [0, 20, 0, 0],
            };

         return []
      },
      content: content,
      pageBreakBefore: function (currentNode, followingNodesOnPage) {
         return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
      },
      footer,
      styles: {
         header: {
            fontSize: 18,
            bold: true,
            alignment: 'right',
            margin: [0, 190, 0, 80]
         },
         content: {
            fontSize: 12
         },
         zebraStyle: {
            fontSize: 10,
         },
         noBordersStyle: {
            fontSize: 9,
         }
      }
   }

   pdfMake.createPdf(docDefinitions).open();

}

