<template>
  <div class="company">
    <PageTitle icon="fa fa-building fa-lg" main="Loja" path="/companyAdd" />
    <div>
      <b-row align-h="center">
        <b-col md="8" sm="12" class="my-1">
          <b-form-group
            label="Pesquisa"
            label-for="filter-input"
            label-cols-sm="2"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escreva para pesquisar"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table
      id="table-company"
      small
      hover
      striped
      :items="companies"
      :fields="fields"
      :current-page="page"
      :per-page="limit"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      empty-text="Não há registros correspondentes à sua solicitação"
      empty-filtered-text="Não há registros correspondentes à sua solicitação"
    >
      <template #cell(actions)="data">
        <b-button
          variant="warning"
          size="sm"
          @click="edit(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-pencil"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="remove(data.item)"
          class="mr-2"
        >
          <i class="fa fa-trash"></i>
        </b-button>
      </template>
    </b-table>
    <b-row align-h="between">
      <b-col md="4" sm="6" class="my-1">
        <b-pagination
          class="my-1"
          v-if="count > limit"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          aria-controls="table-confirmed"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col md="3" sm="6" class="my-1">
        <b-form-group
          label="Por página:"
          label-for="per-page-select"
          label-cols-sm="8"
          label-align-sm="right"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="limit"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";

export default {
  name: "Company",
  components: { PageTitle },
  data: function () {
    return {
      companies: [],
      page: 1,
      limit: 10,
      count: 0,
      filter: null,
      pageOptions: [10, 20, 30],
      fields: [
        { key: "id", label: "Código", sortable: true },
        { key: "name", label: "Nome", sortable: true },
        { key: "cnpj", label: "CNPJ", sortable: true },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    loadCompanies() {
      const url = `${baseApiUrl}/company`;
      axios.get(url).then((res) => {
        this.companies = res.data.companies;
        this.count = res.data.count;
      });
    },
    remove(company) {
      if (
        confirm(`Tem certeza que deseja excluir o associado ${company.name}?`)
      ) {
        axios
          .delete(`${baseApiUrl}/company/${company.id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadCompanies();
          })
          .catch(showError);
      }
    },
    edit(id) {
      this.$router.push(`/companyAdd/${id}`);
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length;
      this.page = 1;
    },
  },
  mounted() {
    this.loadCompanies();
  },
};
</script>

<style>
</style>