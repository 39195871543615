<template>
  <div class="purchase">
    <PageTitle
      icon="fa fa-bar-chart fa-lg"
      main="Lançamentos Associado"
      path="/purchaseAdd"
    />
    <b-table
      small
      hover
      striped
      :items="purchases"
      :fields="fields"
      sort-icon-left
    >
      <template #cell(actions)="data">
        <b-button
          variant="warning"
          size="sm"
          @click="edit(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-pencil"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="remove(data.item)"
          class="mr-2"
        >
          <i class="fa fa-times"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import moment from "moment";
export default {
  name: "purchase",
  components: { PageTitle },
  data: function () {
    return {
      purchases: [],
      fields: [
        { key: "id", label: "Código", sortable: true },
        { key: "target", label: "Meta", sortable: true },
        {
          key: "value",
          label: "Valor",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          key: "dateMove",
          label: "Movimento",
          formatter: "formatDate",
          sortable: true,
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    loadPurchases() {
      const url = `${baseApiUrl}/purchase`;
      axios.get(url).then((res) => {
        this.purchases = res.data;
      });
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    remove(purchase) {
      if (
        confirm(
          `Tem certeza que deseja inativar a meta ${purchase.description}?`
        )
      ) {
        axios
          .delete(`${baseApiUrl}/purchase/${purchase.id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadPurchases();
          })
          .catch(showError);
      }
    },
    edit(id) {
      this.$router.push(`/purchaseAdd/${id}`);
    },
  },
  mounted() {
    this.loadPurchases();
  },
};
</script>

<style>
</style>