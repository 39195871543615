<template>
  <div class="provider-add">
    <PageTitle icon="fa fa-industry fa-lg" main="Cadastro Fornecedor" />
    <b-form class="provider-form-add">
      <input v-if="id" type="hidden" id="provider-id" v-model="provider.id" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Razão Social" label-for="provider-name">
            <b-form-input
              id="provider-name"
              v-model="provider.name"
              trim
              autofocus
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="60"
              :state="validateState('name')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Nome fantasia" label-for="provider-fantasy">
            <b-form-input
              id="provider-fantasy"
              v-model="provider.fantasy"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CNPJ" label-for="provider-cnpj">
            <TheMask
              id="provider-cnpj"
              v-model="provider.cnpj"
              mask="##.###.###/####-##"
              class="form-control"
              :class="{ 'is-invalid': validateState('cnpj') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="CEP" label-for="provider-zipcode">
            <TheMask
              id="provider-zipcode"
              v-model="provider.zipcode"
              mask="#####-###"
              class="form-control"
              :class="{ 'is-invalid': validateState('zipcode') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group label="Logradouro" label-for="provider-address">
            <b-form-input
              id="provider-address"
              v-model="provider.address"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="45"
              :state="validateState('address')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Numero" label-for="provider-number">
            <TheMask
              id="provider-number"
              v-model="provider.number"
              mask="######"
              class="form-control"
              :class="{ 'is-invalid': validateState('number') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Bairro" label-for="provider-district">
            <b-form-input
              id="provider-district"
              v-model="provider.district"
              trim
              autocomplete="off"
              lazy-formatter
              :formatter="formatter"
              maxlength="20"
              :state="validateState('district')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="Complemento" label-for="provider-complement">
            <b-form-input
              id="provider-complement"
              v-model="provider.complement"
              trim
              autocomplete="off"
              lazy-formatter
              :formatter="formatter"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Cidade" label-for="provider-city">
            <b-form-input
              id="provider-city"
              v-model="provider.city"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="20"
              :state="validateState('city')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="12">
          <b-form-group label="UF" label-for="provider-state">
            <b-form-input
              id="provider-state"
              v-model="provider.state"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="2"
              :state="validateState('state')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Telefone" label-for="provider-phone">
            <TheMask
              id="provider-phone"
              v-model="provider.phone"
              :mask="['(##) ####-####', '(##) #####-####']"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="E-mail" label-for="provider-email">
            <b-form-input
              type="email"
              id="provider-email"
              v-model="provider.email"
              trim
              lazy-formatter
              autocomplete="off"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="Responsável" label-for="provider-responsible">
            <b-form-input
              id="provider-responsible"
              v-model="provider.responsible"
              trim
              autocomplete="off"
              maxlength="20"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { TheMask } from "vue-the-mask";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ProviderAdd",
  props: ["id"],
  components: { PageTitle, TheMask },
  data: function () {
    return {
      provider: {},
    };
  },
  validations: {
    provider: {
      name: {
        required,
      },
      cnpj: {
        required,
      },
      zipcode: {
        required,
      },
      address: {
        required,
      },
      number: {
        required,
      },
      district: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      this.$v.provider.$touch();
      if (!this.$v.provider.$invalid) {
        const method = this.provider.id ? "put" : "post";
        const id = this.provider.id ? `/${this.provider.id}` : "";
        const url = `${baseApiUrl}/provider${id}`;
        axios[method](url, this.provider)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.$router.push("/provider");
          })
          .catch(showError);
      }
    },
    reset() {
      this.provider = {};
      this.$v.provider.$reset();
    },
    back() {
      this.$router.push("/provider");
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.provider[name];
      return $dirty && $error ? !$error : null;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.provider.id = this.$route.params.id;
      const url = `${baseApiUrl}/provider/${this.provider.id}`;
      axios.get(url).then((res) => {
        this.provider = res.data;
      });
    }
  },
};
</script>

<style>
.provider-form-add {
  padding: 20px;
}
</style>