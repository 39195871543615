<template>
  <div class="purchase-add">
    <PageTitle icon="fa fa-bar-chart fa-lg" main="Lançamento Associado" />
    <b-form class="purchase-form-add">
      <input v-if="id" type="hidden" id="purchase-id" v-model="purchase.id" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Meta" label-for="purchase-target">
            <b-form-select
              id="purchase-target"
              v-model="purchase.idTarget"
              :options="options"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3 " sm="12">
          <b-form-group label="Movimento" label-for="move-date">
            <b-form-datepicker
              id="move-date"
              placeholder="Selecione"
              v-model="purchase.dateMove"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Documento" label-for="purchase-document">
            <TheMask
              id="purchase-document"
              v-model="purchase.document"
              mask="##########"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Valor" label-for="purchase-value">
            <b-input-group prepend="R$">
              <Money
                id="purchase-value"
                class="form-control"
                v-model="purchase.value"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <b-form-group label="Observação" label-for="purchase-note">
            <b-form-textarea
              id="purchase-note"
              rows="1"
              v-model="purchase.note"
              maxlength="100"
              lazy-formatter
              :formatter="formatter"
              no-resize
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
// const maskprovider = (provider) => {
//   return provider.replace(
//     /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
//     "$1.$2.$3/$4-$5"
//   );
// };
export default {
  name: "purchaseAdd",
  props: ["id"],
  components: { PageTitle, Money, TheMask },
  data: function () {
    return {
      purchase: {},
      options: [{ value: null, text: "Por favor selecione a opção" }],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      const method = this.purchase.id ? "put" : "post";
      const id = this.purchase.id ? `/${this.purchase.id}` : "";
      const url = `${baseApiUrl}/purchase${id}`;
      axios[method](url, this.purchase)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/purchase");
        })
        .catch(showError);
    },
    reset() {
      this.purchase = {};
    },
    back() {
      this.$router.push("/purchase");
    },
  },
  created() {
    const url = `${baseApiUrl}/target`;
    axios.get(url).then((res) => {
      this.options = res.data.targets.map((target) => ({
        value: target.id,
        text: `${target.description}`,
      }));
    });
  },
  mounted() {
    if (this.$route.params.id) {
      this.purchase.id = this.$route.params.id;
      const url = `${baseApiUrl}/purchase/${this.purchase.id}`;
      axios.get(url).then((res) => {
        this.purchase = res.data;
        this.purchase.value = this.purchase.value.replace(/\./g, ",");
      });
    }
  },
};
</script>

<style>
.purchase-form-add {
  padding: 20px;
}
</style>