<template>
  <div class="launch-add">
    <PageTitle icon="fa fa-money fa-lg" main="Adicionar Lançamento Fundo Fixo" />
    <b-form class="acLaunch-form-add">
      <b-row>
        <b-col md="4" sm="12">
          <b-form-group label="Operação" label-for="acLaunch-operation">
            <b-form-select
              id="acLaunch-operation"
              v-model="acLaunch.idOperation"
              :options="optionsOperation"
              @change="typeOperation"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione a operação --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Conta corrente" label-for="acLaunch-operation">
            <b-form-select
              id="acLaunch-operation"
              v-model="acLaunch.idAccount"
              :options="optionsAccount"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione a conta --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Categoria" label-for="acLaunch-category">
            <b-form-select
              id="acLaunch-category"
              v-model="acLaunch.idCategory"
              :options="optionsCategory"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione a categoria --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Tipo" label-for="acLaunch-type">
            <b-form-input
              id="acLaunch-type"
              disabled
              :value="descriptionType"
              trim
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Movimento" label-for="acLaunch-dateMove">
            <b-form-datepicker
              id="acLaunch-dateMove"
              placeholder="Selecione"
              v-model="acLaunch.dateMove"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Valor" label-for="acLaunch-value">
            <b-input-group prepend="R$">
              <Money
                id="acLaunch-value"
                v-model="acLaunch.value"
                class="form-control"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12">
          <b-form-group label="Observação" label-for="acLaunch-note">
            <b-form-input
              id="acLaunch-note"
              trim
              v-model="description.note"
              autocomplete="off"
              :formatter="formatter"
              maxlength="80"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
export default {
  name: "account-launch-add",
  components: { PageTitle, Money },
  data: function () {
    return {
      acLaunch: {
        idOperation: null,
        idAccount: null,
        idCategory: null,
        typeOperation: null,
      },
      optionsOperation: [],
      optionsAccount: [],
      optionsCategory: [],
      operationsType: [],
      description: {},
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    getOperation() {
      const url = `${baseApiUrl}/operation`;
      axios.get(url).then((res) => {
        this.optionsOperation = res.data.map((operation) => {
          return {
            value: operation.id,
            text: operation.description,
          };
        });
        this.operationsType = res.data;
      });
    },
    getAccount() {
      const url = `${baseApiUrl}/account`;
      axios.get(url).then((res) => {
        this.optionsAccount = res.data.map((account) => {
          return {
            value: account.id,
            text: account.description,
          };
        });
      });
    },
    getCategory(type) {
      const url1 = `${baseApiUrl}/categories-revenue`;
      const url2 = `${baseApiUrl}/categories-expense`;
      axios.get(type === 'C'? url1 : url2).then((res) => {
        this.optionsCategory = res.data.map((category) => {
          return {
            value: category.id,
            text: category.description,
          };
        });
      });
    },
    typeOperation(value) {
      const typeSelected = this.operationsType.filter((operation) => {
        return operation.id === value;
      });
      this.acLaunch.typeOperation = typeSelected[0].type;
      this.description.operation = typeSelected[0].description;
      this.getCategory(typeSelected[0].type)
    },
    formatter: (value) => {
      return value.toUpperCase();
    },
    reset() {
      this.acLaunch = {
        idOperation: null,
        typeOperation: null,
      };
      this.description = {};
    },
    back() {
      this.$router.push("/acLaunch");
    },
    save() {
      this.acLaunch.description = `${this.description.operation} - ${
        this.description.note ?? ""
      }`;
      if (this.acLaunch.typeOperation === "D") {
        this.acLaunch.value = this.acLaunch.value * -1;
      }
      const url = `${baseApiUrl}/acLaunch`;
      axios
        .post(url, this.acLaunch)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/acLaunch");
        })
        .catch(showError);
    },
  },
  // watch:{
  //   'acLaunch.idOperation':{
  //     handler(newValue, oldValue) {
  //       if (newValue !== oldValue) {
  //         this.getCategory()
  //       }
  //     },
  //     deep: true,
  //   }
  // },
  computed: {
    descriptionType() {
      if (this.acLaunch.typeOperation === "C") {
        return "CREDITO";
      } else if (this.acLaunch.typeOperation === "D") {
        return "DEBITO";
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.getOperation();
    this.getAccount();
  },
};
</script>
   
<style>
.acLaunch-form-add {
  padding: 20px;
}
</style>