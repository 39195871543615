import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatterValue } from '../utils/formatterData'
import { header, footer, pageA4Margins, marginFilter } from '../utils/template'

// function percentage(value, base) {
//    const result = (parseFloat(value) / parseFloat(base) * 100)
//    return `${result.toLocaleString("pt-BR", {
//       maximumFractionDigits: 3,
//       minimumFractionDigits: 3
//    })}%`
// }

export function pendentSum(pendencies, filters) {
   pdfMake.vfs = pdfFonts.pdfMake.vfs;
   pdfMake.tableLayouts = {
      myZebraLayout: {
         hLineWidth() {
            return 0;
         },
         vLineWidth() {
            return 0;
         },
         paddingLeft(i) {
            return i && 4 || 0;
         },
         paddingRight(i, node) {
            return (i < node.table.widths.length - 1) ? 4 : 0;
         },
         fillColor(rowIndex) {
            return (rowIndex % 2 === 0) ? '#BBBBBB' : '#DDDDDD';
         }
      },
      myNoBordersLayout: {
         hLineWidth(i, node) {
            if (i === 0 || i === node.table.body.length) {
               return 0;
            }
            return (i === node.table.headerRows) ? 2 : 0;
         },
         vLineWidth() {
            return 0;
         },
         paddingLeft(i) {
            return i === 0 ? 0 : 8;
         },
         paddingRight(i, node) {
            return (i === node.table.widths.length - 1) ? 0 : 8;
         },
         paddingBottom: function (i, node) {
            if (i === (node.table.headerRows - 1))
               return 0
            return 10;
         },
      }
   }
   const title = "Relatório Pendencia Verba";
   const target = filters.idTarget ? filters.idTarget : '[TODOS]'
   const move = (filters.initial && filters.end) ? `${filters.initial} - ${filters.end}` : '[TODOS]'
   const year = filters.year ? `${filters.year}` : '[TODOS]'
   const infoFilters = {
      text: [
         "Filtros: ",
         `Meta: ${target}; `,
         `Periodo: ${move}; `,
         `Ano: ${year}; `,
      ],
      alignment: 'center',
      fontSize: 8,
      margin: marginFilter
   }

   const bodyHeader = {
      table: {
         headerRows: 1,
         widths: ['*', 70, 70],
         body: [
            [
               { text: 'META', bold: true },
               { text: 'REFERENCIA', bold: true },
               { text: 'VALOR', bold: true },
            ],
            ['', '', '']
         ]
      },
      layout: 'headerLineOnly',
      style: 'noBordersStyle'
   }

   // const valuePendente = pendencies.map(pendencie => revenue.Pendente || 0)
   //    .reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))

   // const valueConfirmado = pendencies.map(revenue => revenue.Confirmado || 0)
   //    .reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))

   // const resume = {
   //    margin: [0, 10, 0, 0],
   //    table: {
   //       widths: [300, 80, 80, 80],
   //       body: [
   //          [
   //             { text: 'TOTAIS', bold: true },
   //             { text: formatterValue(valuePendente), bold: true },
   //             { text: formatterValue(valueConfirmado), bold: true },
   //             { text: '-' }
   //          ],
   //       ]
   //    },
   //    layout: 'myZebraLayout',
   //    style: 'noBordersStyle'
   // }

   const data = [
      {
         margin: [0, 0, 0],
         table: {
            widths: ['*', 70, 70],
            body: pendencies.map(pendencie => {
               return [
                  { text: pendencie.target, bold: true },
                  { text: `${pendencie.referenceMonth}/${pendencie.referenceYear}`, bold: true },
                  { text: formatterValue(pendencie.valueReturn), bold: true },
               ]
            }),
         },
         layout: 'myZebraLayout',
         style: 'zebraStyle'
      }]


   const content = [
      infoFilters,
      bodyHeader,
      // resume,
      ...data,
   ];


   const docDefinitions = {
      info: {
         title: title,
         author: 'Construmap',
      },
      pageSize: 'A4',
      pageMargins: pageA4Margins,
      header: header(title),
      content: content,
      pageBreakBefore: function (currentNode, followingNodesOnPage) {
         return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
      },
      footer,
      styles: {
         header: {
            fontSize: 18,
            bold: true,
            alignment: 'right',
            margin: [0, 190, 0, 80]
         },
         content: {
            fontSize: 12
         },
         zebraStyle: {
            fontSize: 9,
         },
         noBordersStyle: {
            fontSize: 10,
         }
      }
   }

   pdfMake.createPdf(docDefinitions).open();

}

