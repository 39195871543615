<template>
  <div>
    <PageTitle icon="fa fa-desktop fa-lg" main="Desempenho de faturamento" />
    <div class="form-filter">
      <b-form class="form-monitoring" inline>
        <label class="mr-sm-2" for="provider">Meta</label>
        <b-form-select
          size="sm"
          id="provider"
          class="mb-2 mr-sm-2 mb-sm-0"
          :options="optionsTarget"
          v-model="filter.idTarget"
        >
          <template #first>
            <b-form-select-option :value="null"
              >-- TODOS --</b-form-select-option
            >
          </template></b-form-select
        >
        <b-form-group class="mr-sm-2" label="Inicio" label-for="filter-input">
          <b-form-select
            size="sm"
            id="provider"
            class="mb-2 mr-sm-2 mb-sm-0 ml-2"
            v-model="filter.initial"
            :options="optionsPeriod"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group class="mr-sm-2" label="Fim" label-for="filter-input">
          <b-form-select
            size="sm"
            id="provider"
            class="mb-2 mr-sm-2 mb-sm-0 ml-2"
            v-model="filter.end"
            :options="optionsPeriod"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group label="Ano" label-for="filter-input">
          <b-form-select
            size="sm"
            id="provider"
            class="mb-2 mr-sm-2 mb-sm-0 ml-2"
            v-model="filter.year"
            :options="optionsYears"
          />
        </b-form-group>
        <b-button variant="primary" size="sm" @click="generatePdf"
          >Gerar Relatorio</b-button
        >
      </b-form>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { performanceRevenues } from "../report/targetReport/performanceRevenues";
export default {
  name: "ReportPerformance",
  components: { PageTitle },
  data: () => ({
    filter: {
      year: "2024",
      idTarget: null,
      initial: "01",
      end: "12",
    },
    dataReport: [],
    optionsTarget: [],
    optionsPeriod: [
      { value: "01", text: "Janeiro" },
      { value: "02", text: "Fevereiro" },
      { value: "03", text: "Março" },
      { value: "04", text: "Abril" },
      { value: "05", text: "Maio" },
      { value: "06", text: "Junho" },
      { value: "07", text: "Julho" },
      { value: "08", text: "Agosto" },
      { value: "09", text: "Setembro" },
      { value: "10", text: "Outubro" },
      { value: "11", text: "Novembro" },
      { value: "12", text: "Dezembro" },
    ],
    optionsYears: [
      { value: "2024", text: "2024" },
      { value: "2023", text: "2023" },
      { value: "2022", text: "2022" },
      { value: "2021", text: "2021" },
    ]
    
  }),
  methods: {
    generatePdf() {
      const url = `${baseApiUrl}/report/reportPerformance`;
      axios
        .get(url, {
          params: this.filter,
        })
        .then((res) => {
          if (res.data.length <= 0) {
            showError("Não há registros correspondentes à sua solicitação");
            return;
          }
          this.dataReport = res.data;
          performanceRevenues(this.dataReport, this.filter);
        })
        .catch(showError);
    },
    getTarget() {
      const url = `${baseApiUrl}/target`;
      axios.get(url).then((res) => {
        this.optionsTarget = res.data.targets.map((target) => {
          return {
            value: target.id,
            text: target.description,
          };
        });
      });
    },
  },
  mounted() {
    this.getTarget();
  },
};
</script>

<style>
.form-monitoring {
  padding: 5px;
  justify-content: center;
}
</style>