<template>
  <header class="header">
    <div class="menu-left">
      <div class="title">
        <router-link to="/"
          ><img id="logo" class="logo" src="@/assets/logo.png" alt=""
        /></router-link>
      </div>
      <a href="#" class="toggle" @click="toggleMenu" v-if="!hideToggle">
        <i class="fa fa-lg" :class="icon"></i>
      </a>
    </div>
    <div class="user-dropdown">
      <UserDropdown v-if="!hideUserDropdown" />
    </div>
  </header>
</template>

<script>
import UserDropdown from "./UserDropdown";
export default {
  name: "Header",
  components: { UserDropdown },
  props: {
    title: String,
    hideToggle: Boolean,
    hideUserDropdown: Boolean,
  },
  computed: {
    icon() {
      return this.$store.state.isMenuVisible
        ? "fa-angle-left"
        : "fa-angle-right";
    },
  },
  methods: {
    toggleMenu() {
      this.$store.commit("toggleMenu");
    },
  },
};
</script>

<style>
.header {
  grid-area: header;
  background-color: #ffff00;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.title {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  padding: 0px 16px;
  justify-self: flex-start;
  width: 200px;
  margin: 0;
}

header.header > div.menu-left > a.toggle {
  width: 50px;
  height: 100%;
  color: #d20001;
  background-color: rgba( 255,255, 255, 0.6 );
  justify-self: flex-start;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

header.header > div.menu-left > a.toggle:hover {
  background-color: #d20001;
  color: #ffff00;
}
.logo {
  height: 50px;
}
.user-dropdown{
  max-width: 250px;
}
</style>