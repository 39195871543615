import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatterValue, formatterDate } from '../utils/formatterData'
import { header, footer, pageA4Margins, marginFilter } from '../utils/template'

export function accountLaunch(launchs, filters) {
   try {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const title = "Relatório Fundo Fixo";
      const release = (filters.launchInitial && filters.launchFinal) ? `${formatterDate(filters.launchInitial)} - ${formatterDate(filters.launchFinal)}` : '[TODOS]'
      const move = (filters.moveInitial && filters.moveFinal) ? `${formatterDate(filters.moveInitial)} - ${formatterDate(filters.moveFinal)}` : '[TODOS]'
      const infoFilters = {
         text: [
            "Filtros: ",
            `Lançamento: ${release}; `,
            `Movimento: ${move}; `,
         ],
         alignment: 'center',
         fontSize: 8,
         margin: marginFilter
      }
   
      const bodyHeader = [{ text: 'Operação', bold: true }, { text: 'Emissão', bold: true }, { text: 'Movimento', bold: true }, { text: 'Valor', bold: true }];
   
      const result = launchs.filter((acc) => acc.reversed === null);
      const data = result.map(acc => {
         console.log(acc)
         if (acc.reversed == null) {
            const valueNominal = formatterValue(acc.value);
            const dateRelease = formatterDate(acc.dateRelease);
            const dateMove = formatterDate(acc.dateMove);
            const colorValue = acc.typeOperation === 'C' ? '#00FF00' : '#FF0000'
            return [acc.operation, dateRelease, dateMove, { text: valueNominal, color: colorValue }]
         }
      });
   
      const valueCredit = result.map(acc => {
         if (acc.typeOperation == 'C')
            return acc.value
         return 0;
      }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))
   
      const valueDebit = result.map(acc => {
         if (acc.typeOperation === 'D')
            return acc.value
         return 0;
      }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))
   
   
      const content = [
         infoFilters,
         {
            table: {
               headerRows: 1,
               widths: ['*', 'auto', 'auto', 'auto'],
               body: [
                  bodyHeader,
                  ...data,
               ],
            },
            layout: {
               hLineWidth(i, node) {
                  if (i === 0 || i === node.table.body.length) {
                     return 0;
                  }
                  return (i === node.table.headerRows) ? 2 : 0;
               },
               vLineWidth() {
                  return 0;
               },
               paddingLeft(i) {
                  return i === 0 ? 0 : 8;
               },
               paddingRight(i, node) {
                  return (i === node.table.widths.length - 1) ? 0 : 8;
               },
               paddingBottom: function (i, node) {
                  if (i === (node.table.headerRows - 1))
                     return 0
                  return 10;
               },
            },
            style: {
               fontSize: 9,
            }
         },
         { text: 'Resumo Lançamentos Financeiros', alignment: 'center', margin: [0, 20, 0, 5], bold: true, fontSize: 14 },
         {
            table: {
               widths: ['*', 'auto'],
               body: [
                  [
                     { text: "Credito (+)", margin: [10, 0], bold: true },
                     { text: formatterValue(valueCredit), margin: [10, 0], bold: true },
                  ],
                  [
                     { text: "Debito (-)", margin: [10, 0], bold: true },
                     { text: formatterValue(valueDebit), margin: [10, 0], bold: true },
                  ],
                  [
                     { text: "Valor Total", margin: [10, 0], bold: true },
                     { text: formatterValue(valueCredit - (valueDebit * -1)), margin: [10, 0], bold: true },
                  ],
               ],
            },
            layout: {
               hLineWidth() {
                  return 0;
               },
               vLineWidth() {
                  return 0;
               },
               paddingLeft(i) {
                  return i && 4 || 0;
               },
               paddingRight(i, node) {
                  return (i < node.table.widths.length - 1) ? 4 : 0;
               },
               fillColor(rowIndex) {
                  return (rowIndex % 2 === 0) ? '#BBBBBB' : '#DDDDDD';
               }
            },
            style: {
               fontSize: 10,
            }
         }
      ];
   
      const docDefinitions = {
         info: {
            title: title,
            author: 'Construmap',
         },
         pageSize: 'A4',
         pageMargins: pageA4Margins,
         header: header(title),
         content: content,
         pageBreakBefore: function (currentNode, followingNodesOnPage) {
            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
         },
         footer,
         styles: {
            header: {
               fontSize: 18,
               bold: true,
               alignment: 'right',
               margin: [0, 190, 0, 80]
            },
            content: {
               fontSize: 12
            },
         }
      }
   
      pdfMake.createPdf(docDefinitions).open();
   
   } catch (error) {
      console.error(error)
   }
}

