<template>
  <div>
    <span class="vw-result">{{ balance.descriptionMain }}</span>
    <b-row> </b-row>
    <b-table
      responsive
      small
      hover
      striped
      :items="balance.children"
      :fields="fields"
      sort-icon-left
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'category' ? '500px' : '150px' }"
        >
      </template>
      <template #cell(value)="data">
        <span
          class="positive"
          :class="{ negative: parseFloat(data.item.value) < 0 }"
          >{{ data.value }}</span
        >
      </template></b-table
    >
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BalanceDetail",
  props: ["balance"],
  data: () => ({
    fields: [
      { key: "category", label: "Descrição" },
      { key: "dateRelease", label: "Lançamento", formatter: "formatDate" },
      { key: "dateMove", label: "Movimento", formatter: "formatDate" },
      {
        key: "value",
        label: "Valor",
        sortable: true,
        formatter: (value) => {
          return parseFloat(value).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
    ],
  }),
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    getReturnResumo() {
      const result = this.balance.children
        .map((res) => res.value)
        .reduce(function (acumulador, atual) {
          return parseFloat(acumulador) + parseFloat(atual);
        });
      return (
        parseFloat(this.balance.previousBalance) + parseFloat(result)
      ).toFixed(2);
    },
  },
};
</script>

<style>
</style>