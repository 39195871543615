<template>
  <div class="receive-add">
    <PageTitle
      icon="fa fa-arrow-circle-right fa-lg"
      main="Cadastro conta receber"
    />
    <b-form class="acReceive-form-add">
      <input v-if="id" type="hidden" id="acReceive-id" v-model="acReceive.id" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Participante" label-for="acReceive-participant">
            <b-form-select
              id="acReceive-participant"
              v-model="acReceive.idParticipant"
              :options="optionsParticipant"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione o participante --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Forma pagamento" label-for="document-type">
            <b-form-select
              id="document-type"
              v-model="acReceive.idDocumentType"
              :options="optionsDocument"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Categoria" label-for="acReceive-category">
            <b-form-select
              id="acReceive-category"
              v-model="acReceive.idCategory"
              :options="optionsCategory"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione a categoria --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2 " sm="12">
          <b-form-group label="Emissao" label-for="move-release">
            <b-form-datepicker
              id="move-release"
              placeholder="Selecione"
              v-model="acReceive.dateRelease"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2 " sm="12">
          <b-form-group label="Vencimento" label-for="move-due">
            <b-form-datepicker
              id="move-due"
              placeholder="Selecione"
              v-model="acReceive.dateDue"
              class="mb-2"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Documento" label-for="acReceive-document">
            <TheMask
              id="acReceive-document"
              v-model="acReceive.document"
              mask="##########"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Valor" label-for="acReceive-value">
            <b-input-group prepend="R$">
              <Money
                id="acReceive-value"
                v-model="acReceive.valueNominal"
                class="form-control"
                v-bind="money"
              ></Money>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        
        <b-col md="12" sm="12">
          <b-form-group label="Descrição" label-for="acReceive-note">
            <b-form-input
              id="acReceive-note"
              v-model="acReceive.note"
              trim
              lazy-formatter
              :formatter="formatter"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
const maskprovider = (provider) => {
  return provider.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};
export default {
  name: "account-receive-add",
  props: ["id"],
  components: { PageTitle, Money, TheMask },
  data: function () {
    return {
      acReceive: {
        idParticipant: null,
        idDocumentType: null,
        idCategory: null,
      },
      optionsParticipant: [],
      optionsDocument: [],
      optionsCategory: [],
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      const method = this.acReceive.id ? "put" : "post";
      const id = this.acReceive.id ? `/${this.acReceive.id}` : "";
      const url = `${baseApiUrl}/acReceive${id}`;
      axios[method](url, this.acReceive)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/acReceive");
        })
        .catch(showError);
    },
    reset() {
      this.acReceive = {
        idParticipant: null,
        idDocumentType: null,
      };
    },
    back() {
      this.$router.push("/acReceive");
    },
    getCategory() {
      const url = `${baseApiUrl}/categories-revenue`;
      axios.get(url).then((res) => {
        this.optionsCategory = res.data.map((category) => {
          return {
            value: category.id,
            text: category.description,
          };
        });
      });
    },
    getParticipant() {
      const url = `${baseApiUrl}/participant`;
      axios.get(url).then((res) => {
        this.optionsParticipant = res.data.map((participant) => {
          participant.cnpj = maskprovider(participant.cnpj);
          return {
            value: participant.id,
            text: `${participant.name} - ${participant.cnpj}`,
          };
        });
      });
    },
    getDocument() {
      const url = `${baseApiUrl}/documentType`;
      axios.get(url).then((res) => {
        this.optionsDocument = res.data.map((documentType) => {
          return {
            value: documentType.id,
            text: documentType.description,
          };
        });
      });
    },
  },
  created() {
    this.getParticipant();
    this.getDocument();
    this.getCategory();
  },
  mounted() {
    if (this.$route.params.id) {
      this.acReceive.id = this.$route.params.id;
      const url = `${baseApiUrl}/acReceive/${this.acReceive.id}`;
      axios.get(url).then((res) => {
        this.$delete(res.data, "documentType");
        this.$delete(res.data, "participant");
        this.acReceive = res.data;
        this.acReceive.valueNominal = this.acReceive.valueNominal.replace(
          /\./g,
          ","
        );
      });
    }
  },
};
</script>

<style>
.acReceive-form-add {
  padding: 20px;
}
</style>