<template>
  <div class="account-receive">
    <PageTitle
      icon="fa fa-arrow-circle-right fa-lg"
      main="Contas a receber"
      path="/acReceiveAdd"
    />
    <div class="form-search-receive">
      <b-row align-h="center">
        <b-col md="4" sm="12" class="my-1">
          <b-form-group label="Pesquisa" label-for="filter-input">
            <b-form-input
              size="sm"
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escreva para pesquisar"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group label="Lançamento" label-for="launch-release">
            <b-form-datepicker
              size="sm"
              placeholder="Inicio"
              v-model="paramsSearch.launchInitial"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-form-datepicker
              size="sm"
              placeholder="Fim"
              v-model="paramsSearch.launchFinal"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-form-checkbox-group
              v-model="paramsSearch.status"
              :options="statusOptions"
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="2" sm="12" class="my-1">
          <b-form-group label="Vencimento" label-for="move-release">
            <b-form-datepicker
              size="sm"
              placeholder="Inicio"
              v-model="paramsSearch.dueInitial"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-form-datepicker
              size="sm"
              placeholder="Fim"
              v-model="paramsSearch.dueFinal"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group label="Pagamento" label-for="payment-release">
            <b-form-datepicker
              size="sm"
              placeholder="Inicio"
              v-model="paramsSearch.paymentInitial"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-form-datepicker
              size="sm"
              placeholder="Fim"
              v-model="paramsSearch.paymentFinal"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-button variant="primary" size="sm" @click="loadSearch"
              >Pesquisar</b-button
            >
            <b-button
              class="ml-2"
              :disabled="isfiltering"
              variant="secondary"
              size="sm"
              @click="generatePdf"
              >Gerar PDF</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table
      small
      hover
      striped
      :items="received"
      :fields="fields"
      :current-page="page"
      :per-page="limit"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      empty-text="Não há registros correspondentes à sua solicitação"
      empty-filtered-text="Não há registros correspondentes à sua solicitação"
      sort-icon-left
    >
      <template #cell(actions)="data">
        <b-button
          variant="info"
          size="sm"
          @click="info(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-eye"></i>
        </b-button>
        <b-button
          variant="warning"
          size="sm"
          :disabled="data.item.status !== 'A'"
          @click="edit(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-pencil"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          :disabled="data.item.status !== 'A'"
          @click="remove(data.item)"
          class="mr-2"
        >
          <i class="fa fa-times"></i>
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          :disabled="data.item.status !== 'A'"
          @click="liquidate(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-dollar"></i>
        </b-button>
      </template>
    </b-table>
    <b-row align-h="between">
      <b-col md="4" sm="6" class="my-1">
        <b-pagination
          class="my-1"
          v-if="count > limit"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          aria-controls="table-ac-receive"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col md="3" sm="6" class="my-1">
        <b-form-group
          label="Por página:"
          label-for="per-page-select"
          label-cols-sm="8"
          label-align-sm="right"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="limit"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <ModalVwReceive
      @reloadPage="loadReceives"
      @reloadModal="info"
      pathHistory="acReceiveHistory"
      pathReversal="acReceiveLiquidate"
      :data="receiveSelected"
      main="Visualização de recebimento"
    />
    <ModalLiquidate
      @reloadPage="loadReceives"
      :data1="liquidateSelected"
      main="Liquidação conta a receber"
      path="acReceiveLiquidate"
    />
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import moment from "moment";
import ModalVwReceive from "./ModalViewReceive";
import ModalLiquidate from "./ModalLiquidate";
import { accountReceive } from "../report/financialReport/accountReceive";
export default {
  name: "account-receive",
  components: { PageTitle, ModalVwReceive, ModalLiquidate },
  data: function () {
    return {
      received: [],
      receiveSelected: {},
      liquidateSelected: {},
      paramsSearch: {},
      isfiltering: false,
      page: 1,
      limit: 10,
      count: 0,
      teste: null,
      filter: null,
      pageOptions: [10, 20, 30],
      statusOptions: [
        { text: "Aberto", value: "A" },
        { text: "Liquidado", value: "L" },
      ],
      fields: [
        { key: "id", label: "Código", sortable: true },
        { key: "participant", label: "Participante", sortable: true },
        { key: "document", label: "Documento", sortable: true },
        {
          key: "dateDue",
          label: "Vencimento",
          sortable: true,
          formatter: "formatDate",
        },
        {
          key: "valueNominal",
          label: "Valor",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            if (value === "C") {
              return "CANCELADO";
            } else if (value === "L") {
              return "LIQUIDADO";
            } else if (value === "A") {
              return "ABERTO";
            }
          },
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    loadReceives() {
      const url = `${baseApiUrl}/acReceive`;
      axios
        .get(url, {
          params: this.paramsSearch,
        })
        .then((res) => {
          this.received = res.data.accountReceive;
          this.count = res.data.count;
          this.isfiltering = false;
        });
    },
    generatePdf() {
      if (this.received.length <= 0) {
        showError("Não há registros correspondentes à sua solicitação");
        return;
      }
      accountReceive(this.received, this.paramsSearch);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    remove(received) {
      if (confirm(`Tem certeza que deseja cancelar a conta ${received.id}?`)) {
        axios
          .delete(`${baseApiUrl}/acReceive/${received.id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadReceives();
          })
          .catch(showError);
      }
    },
    async info(id) {
      const url = `${baseApiUrl}/acReceive/${id}`;
      await axios.get(url).then((res) => {
        this.receiveSelected = res.data;
        this.receiveSelected.valueNominal =
          this.receiveSelected.valueNominal.replace(/\./g, ",");
      });
      this.$bvModal.show("modal-vw-receive");
    },
    async liquidate(id) {
      const url = `${baseApiUrl}/acReceive/${id}`;
      await axios.get(url).then((res) => {
        this.liquidateSelected = res.data;
      });
      this.$bvModal.show("modal-vw-liquidate");
    },
    edit(id) {
      this.$router.push(`/acReceiveAdd/${id}`);
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length;
      this.page = 1;
    },
    loadSearch() {
      this.loadReceives();
    },
  },
  watch: {
    paramsSearch: {
      handler() {
        this.isfiltering = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.loadReceives();
  },
};
</script>

<style>
label.label-search {
  width: 100%;
}
.form-search-receive .form-group {
  margin-bottom: 0.3rem;
}
</style>