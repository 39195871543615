<template>
  <div class="user-dropdown">
    <button class="user-button">
      <span class="d-none d-sm-block">{{ user.name }}</span>
      <b-avatar class="user-dropdown-avatar"></b-avatar>
      <i class="fa fa-angle-down"></i>
    </button>
    <div
      class="user-dropdown-content"
    >
      <!-- <a href="#"
        ><i class="fa fa-cog"></i
        ><span class="user-dropdown-item">Configurações</span></a
      > -->
      <a href="#" @click.prevent="logout"
        ><i class="fa fa-sign-out"></i
        ><span class="user-dropdown-item">Sair</span></a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { userKey } from "@/global";
export default {
  name: "UserDropdown",
  computed: mapState(["user"]),
  methods: {
    logout() {
      localStorage.removeItem(userKey);
      this.$store.commit("setUser", null);
      this.$router.push({ name: "auth" });
    },
  },
};
</script>
<style>
.user-dropdown {
  height: 100%;
  position: relative;
}
.user-button {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  color: #d20001;
  font-size: 1rem;
  background-color: rgba(255, 255, 101, 0.2);
  border: 0;
}
.user-button:hover {
  outline: 0 none;
  outline-offset: 0;
  background-color: #d20001;
  color: #ffff00;
}
.user-dropdown-avatar {
  margin: 0 10px;
}
.user-dropdown-content {
  background-color: #ffff00;
  position: absolute;
  right: 10px;
  min-width: 175px;
  box-shadow: 0px 8px 15px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5 linear;
}

.user-dropdown-content a {
  text-decoration: none;
  color: #d20001;
  padding: 10px;
}
.user-dropdown-content a:hover {
  text-decoration: none;
  background-color: #d20001;
  color: #ffff00;
}
.user-dropdown-item {
  margin-left: 5px;
}
.user-dropdown:hover .user-dropdown-content {
  visibility: visible;
  opacity: 1;
}
</style>