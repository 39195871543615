import moment from "moment";
export function formatterStatus(status) {
   let result;
   switch (status) {
      case "C":
         result = "CANCELADO"
         break;
      case "L":
         result = "LIQUIDADO"
         break;
      default:
         result = "ABERTO"
   }
   return result;
}

export function formatterValue(value) {
   if (value)
      return parseFloat(value).toLocaleString("pt-BR", {
         style: "currency",
         currency: "BRL",
      });
   return { text: '-', alignment: 'center' }
}

export function formatterDate(date) {
   if (date)
      return moment(date).format("DD/MM/YYYY");
   return { text: '-', alignment: 'center' }
}

export function getDateNow() {
   return moment(Date.now()).format("DD/MM/YYYY HH:mm:ss")
}