import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { formatterStatus, formatterValue, formatterDate } from '../utils/formatterData'
import { header, footer, marginFilter, pageA4Margins } from '../utils/template'

export function accountReceive(accounts, filters) {
   pdfMake.vfs = pdfFonts.pdfMake.vfs;
   const title = "Relatório de Contas a Receber";

   //filters
   const release = (filters.launchInitial && filters.launchFinal) ? `${formatterDate(filters.launchInitial)} - ${formatterDate(filters.launchFinal)}` : '[TODOS]'
   const due = (filters.dueInitial && filters.dueFinal) ? `${formatterDate(filters.dueInitial)} - ${formatterDate(filters.dueFinal)}` : '[TODOS]'
   const payment = (filters.paymentInitial && filters.paymentFinal) ? `${formatterDate(filters.paymentInitial)} - ${formatterDate(filters.paymentFinal)}` : '[TODOS]'

   //body
   const infoFilters = {
      text: [
         "Filtros: ",
         `Lançamento: ${release}; `,
         `Vencimento: ${due}; `,
         `Pagamento: ${payment}; `,
         `Status: ${filters.status ? filters.status.map(st => formatterStatus(st)) : 'TODOS'}; `,
      ],
      alignment: 'center',
      fontSize: 8,
      margin: marginFilter
   }

   const bodyHeader = [{ text: 'Participante', bold: true }, { text: 'Documento', bold: true }, { text: 'Emissão', bold: true }, { text: 'Vencimento', bold: true }, { text: 'Valor', bold: true }, { text: 'Status', bold: true }];
   const bodySubheader = [{ text: 'Descrição', bold: true }, '', { text: 'Pagamento', bold: true }, { text: 'Desconto', bold: true }, { text: 'Acrescimo', bold: true }, { text: 'Valor Pago', bold: true }];

   const data = accounts.map(acc => {
      const status = formatterStatus(acc.status);
      const valueNominal = formatterValue(acc.valueNominal);
      const valueDiscount = formatterValue(acc.valueDiscount || 0);
      const valuePaid = formatterValue(acc.valuePaid || 0);
      const valueAdd = formatterValue(acc.valueAdd || 0);
      const dateRelease = formatterDate(acc.dateRelease);
      const dateDue = formatterDate(acc.dateDue);
      const datePayment = formatterDate(acc.datePayment);
      return [
         [{ text: acc.participant }, acc.document, dateRelease, dateDue, valueNominal, status],
         [{ text: acc.note != null ? `- ${acc.note}` : '-', alignment: acc.note != null ? 'left' : 'center' }, '', datePayment, valueDiscount, valueAdd, valuePaid]
      ]
   });

   //Bruto
   const valueTotal = accounts.map(acc => {
      if (acc.status !== 'C')
         return acc.valueNominal
      return 0;
   }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))

   const paymentDiscount = accounts.map(acc => {
      if (acc.status == 'L')
         return acc.valueDiscount || 0
      return 0;
   }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))

   const paymentAdd = accounts.map(acc => {
      if (acc.status == 'L')
         return acc.valueAdd || 0
      return 0;
   }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))


   const valueLiquid = valueTotal - paymentDiscount + paymentAdd

   const paymentTotal = accounts.map(acc => {
      if (acc.status !== 'C')
         return acc.valuePaid || 0
      return 0;
   }).reduce((ant, prox) => parseFloat(ant) + parseFloat(prox))



   const content = [
      infoFilters,
      {
         table: {
            headerRows: 2,
            widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
               bodyHeader,
               bodySubheader,
               ...(data.flat()),
            ],

         },
         layout: {
            hLineWidth(i, node) {
               if (i === 0 || i === node.table.body.length) {
                  return 0;
               }
               return (i === node.table.headerRows) ? 2 : 0;
            },
            vLineWidth() {
               return 0;
            },
            paddingLeft(i) {
               return i === 0 ? 0 : 8;
            },
            paddingRight(i, node) {
               return (i === node.table.widths.length - 1) ? 0 : 8;
            },
            paddingBottom: function (i, node) {
               if (i === (node.table.headerRows - 1))
                  return 0
               return ((i % 2) != 0) ? 10 : 2;
            },
         },
         style: {
            fontSize: 9,
         }
      },
      { text: 'Resumo Financeiro a Receber', alignment: 'center', margin: [0, 20, 0, 5], bold: true, fontSize: 14 },
      {
         table: {
            widths: ['*', 'auto'],
            body: [
               [
                  { text: "Valor Bruto", margin: [10, 0], bold: true },
                  { text: formatterValue(valueTotal), margin: [10, 0], bold: true },
               ],
               [
                  { text: "Desconto (-)", margin: [10, 0], bold: true },
                  { text: formatterValue(paymentDiscount), margin: [10, 0], bold: true },
               ],
               [
                  { text: "Acrescimo (+)", margin: [10, 0], bold: true },
                  { text: formatterValue(paymentAdd), margin: [10, 0], bold: true },
               ],
               [
                  { text: "Valor recebido", margin: [10, 0], bold: true },
                  { text: formatterValue(paymentTotal), margin: [10, 0], bold: true },
               ],
               [
                  { text: "Valor á Receber", margin: [10, 0], bold: true },
                  { text: formatterValue(valueLiquid - paymentTotal), margin: [10, 0], bold: true },
               ],
            ],
         },
         layout: {
            hLineWidth() {
               return 0;
            },
            vLineWidth() {
               return 0;
            },
            paddingLeft(i) {
               return i && 4 || 0;
            },
            paddingRight(i, node) {
               return (i < node.table.widths.length - 1) ? 4 : 0;
            },
            fillColor(rowIndex) {
               return (rowIndex % 2 === 0) ? '#BBBBBB' : '#DDDDDD';
            }
         },
         style: {
            fontSize: 10,
         }
      }

   ];

   const docDefinitions = {
      info: {
         title: title,
         author: 'Construmap',
      },
      pageSize: 'A4',
      pageMargins: pageA4Margins,
      header: header(title),
      content: content,
      pageBreakBefore: function (currentNode, followingNodesOnPage) {
         return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
      },
      footer,
      styles: {
         header: {
            fontSize: 18,
            bold: true,
            alignment: 'right',
            margin: [0, 190, 0, 80]
         },
         content: {
            fontSize: 12
         },
      }
   }

   pdfMake.createPdf(docDefinitions).open();

}

