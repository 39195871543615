<template>
  <b-modal
    id="modal-vw-liquidate"
    :title="main"
    no-close-on-esc
    no-close-on-backdrop
    @hidden="resetModal"
    @ok="confirmLiquidate"
    @show="getAccounts"
  >
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="success" @click="ok()"> OK </b-button>
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancelar
      </b-button>
    </template>
    <b-container fluid>
      <b-form-group
        label="Valor Nominal"
        label-for="value-nominal"
        invalid-feedback="Valor da liquidação é obrigatório"
      >
        <Money
          id="value-nominal"
          class="form-control form-control-sm"
          :value="data1.valueNominal"
          v-bind="money"
          disabled
        ></Money>
      </b-form-group>
      <b-form-group label="Forma pagamento" label-for="document-type">
        <b-form-select size="sm" id="document-type" v-model="liquidate.idAccount" :options="accounts">
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- Selecione --</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Data pagamento" label-for="date-liquidate">
        <b-form-datepicker
          id="date-liquidate"
          placeholder="Selecione"
          size="sm"
          required
          v-model="liquidate.datePayment"
          class="mb-2"
          today-button
          label-today-button="Hoje"
          reset-button
          label-reset-button="Limpar"
          close-button
          label-close-button="Fechar"
          locale="pt-BR"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
        ></b-form-datepicker>
      </b-form-group>
      <b-form-group label="Desconto R$" label-for="value-discount">
        <Money
          id="value-discount"
          class="form-control form-control-sm"
          v-model="liquidate.valueDiscount"
          v-bind="money"
        ></Money>
      </b-form-group>
      <b-form-group label="Acrescimo R$" label-for="value-add">
        <Money
          id="value-add"
          class="form-control form-control-sm"
          v-model="liquidate.valueAdd"
          v-bind="money"
        ></Money>
      </b-form-group>
      <b-form-group
        label="Valor pagamento"
        label-for="value-liquidate"
        invalid-feedback="Valor da liquidação é obrigatório"
      >
        <Money
          id="value-liquidate"
          class="form-control form-control-sm"
          :value="valuePaid"
          v-bind="money"
        ></Money>
      </b-form-group>
    </b-container>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { baseApiUrl, showError } from "@/global";
import { Money } from "v-money";
export default {
  name: "ModalLiquidate",
  props: ["main", "data1", "path"],
  components: { Money },
  data: function () {
    return {
      liquidate: {
        datePayment: moment().format("YYYY-MM-DD"),
      },
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      accounts: [],
    };
  },
  methods: {
    loadModal() {
      this.liquidate = this.data1;
    },
    resetModal() {
      this.liquidate = {
        datePayment: moment().format("YYYY-MM-DD"),
      };
    },
    getAccounts() {
      const url = `${baseApiUrl}/account`;
      axios.get(url).then((res) => {
        this.accounts = res.data.map((account) => {
          return {
            value: account.id,
            text: account.description,
          };
        });
      });
    },
    confirmLiquidate(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.liquidate.valuePaid = this.valuePaid;
      this.liquidate.idCategory = this.data1.idCategory
      const url = `${baseApiUrl}/${this.path}/${this.data1.id}`;
      axios
        .put(url, this.liquidate)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$bvModal.hide("modal-vw-liquidate");
          this.$emit("reloadPage");
        })
        .catch(showError);
    },
  },
  computed: {
    valuePaid() {
      const value = this.data1.valueNominal;
      const res =
        value - this.liquidate.valueDiscount + this.liquidate.valueAdd;
      return res;
    },
  },
};
</script>

<style>
</style>