<template>
  <div>
    <PageTitle icon="fa fa-money fa-lg" main="Cadastro Operação Fundo Fixo" />
    <b-form class="operation-form-add">
      <input v-if="id" type="hidden" id="operation-id" v-model="operation.id" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Descrição" label-for="operation-description">
            <b-form-input
              id="operation-description"
              v-model="operation.description"
              :formatter="formatter"
              trim
              autofocus
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Tipo" label-for="operation-type">
            <b-form-select
              v-model="operation.type"
              :options="options"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button variant="secondary" class="mr-2" @click="reset"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
export default {
  name: "OperationAdd",
  props: ["id"],
  components: { PageTitle },
  data: function () {
    return {
      operation: {},
      options: [
        { value: "C", text: "CREDITO" },
        { value: "D", text: "DEBITO" },
      ],
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      const method = this.operation.id ? "put" : "post";
      const id = this.operation.id ? `/${this.operation.id}` : "";
      const url = `${baseApiUrl}/operation${id}`;
      axios[method](url, this.operation)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/operation");
        })
        .catch(showError);
    },
    back() {
      this.$router.push("/operation");
    },
    reset() {
      this.operation = {};
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.operation.id = this.$route.params.id;
      const url = `${baseApiUrl}/operation/${this.operation.id}`;
      axios.get(url).then((res) => {
        this.operation = res.data;
      });
    }
  },
};
</script>

<style>
.operation-form-add {
  padding: 20px;
}
</style>