<template>
  <b-modal
   id="modal-category"
   no-close-on-esc
   no-close-on-backdrop
   title="Categoria"
   size="xl"
   @hidden="resetModal"
  >
  <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="success" @click="save">
        OK
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel()">
        Cancelar
      </b-button>
    </template>
   <b-form-row>
      <b-col cols="6">
         <b-form-group
            label="Descrição"
            label-for="description"
            >
            <b-form-input
            id="description"
            v-model="category.description"
            autocomplete="off"
            required
         ></b-form-input>
         </b-form-group>
      </b-col>
      <b-col cols="3">
         <b-form-group
            label="Tipo"
            label-for="type"
            >
            <b-form-select
            id="type"
            v-model="category.type"
            :options="optionsType"
            required
         ></b-form-select>
         </b-form-group>
      </b-col>
      <b-col cols="3" v-show="category.type == 'A'">
         <b-form-group
            label="Categoria"
            label-for="category"
            >
            <b-form-select
            id="category"
            v-model="category.category"
            :options="optionsCategory"
            required
         ></b-form-select>
         </b-form-group>
      </b-col>
      <b-col cols="12" v-show="category.type == 'A'">
         <b-form-group
         label="Referencia"
            label-for="id_reference"
            >
            <b-form-select
            id="id_reference"
            v-model="category.id_reference"
            :options="sintetics"
            >
         ></b-form-select>
         </b-form-group>
      </b-col>
   </b-form-row>
  </b-modal>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
export default {
   name: "ModalCategorie",
   data: function() {
      return{
         category: {
            id_reference: null,
            category: null,
         },
         sintetics: [],
         optionsCategory: [
            {value: 'R', text:'RECEITA'},
            {value: 'D', text:'DESPESA'},
         ],
         optionsType: [
            {value: 'S', text:'SINTETICA'},
            {value: 'A', text:'ANALITICA'},
         ],
      }
   },
   methods:{
    resetModal() {
      this.category = {
         id_reference: null,
         category: null,
      }
    },
    save() {
      const method = this.category.id ? "put" : "post";
      const id = this.category.id ? `/${this.category.id}` : "";
      const url = `${baseApiUrl}/category-finance${id}`;
      axios[method](url, this.category)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$bvModal.hide("modal-category");
          this.$emit("reloadPage");
        })
        .catch(showError);
    },
    getCategoriesSintetic() {
         const url = `${baseApiUrl}/category-sintetic`;
         axios.get(url).then((res) => {
            this.sintetics = res.data.map((sintetic) => {
               return {
                  value: sintetic.id,
                  text: sintetic.description,
               };
            });
         });
      },
   },
   watch: {
      category: {
      handler(newVal) {
        if (newVal.type === 'A') {
         this.getCategoriesSintetic()
        }
        if (newVal.type === 'S') {
         this.category.id_reference = null;
         this.category.category = null;
        }
      },
      deep: true,
    },
  },
}
</script>

<style>

</style>