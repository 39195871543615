import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      isMenuVisible: false,
      user: null,
      menu: null
   },
   mutations: {
      toggleMenu(state, isVisible) {
         if (!state.user) {
            state.isMenuVisible = false
            return
         }

         if (isVisible === undefined) {
            state.isMenuVisible = !state.isMenuVisible
         } else {
            state.isMenuVisible = isVisible
         }
      },
      setUser(state, user) {
         state.user = user
         if (user) {
            axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
            state.isMenuVisible = true
            state.menu = user.menu
         } else {
            delete axios.defaults.headers.common['Authorization']
            state.isMenuVisible = false
            state.menu = null
         }
      }
   }
})