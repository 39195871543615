<template>
  <aside class="menu" v-show="isMenuVisible">
    <div class="menu-title">MENU</div>
    <ul v-if="user.role == 1">
      <li class="menu-grp">
        <router-link to="/" active-class="active" exact>
          <i class="fa fa-pie-chart"></i>
          <span>Dashboard</span>
        </router-link>
      </li>
    </ul>
    <MenuButton :itens="menu"/>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import MenuButton from "./MenuButton";
export default {
  name: "Menu",
  computed: mapState(["isMenuVisible", "menu", "user"]),
  components: { MenuButton },
  data: function () {
    return {
      hideButtonDropdown: true,
    };
  },
};
</script>

<style>
.menu {
  grid-area: menu;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  overflow: auto;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}
.menu-title {
  display: block;
  padding: 16px;
  font-size: 16px;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
  letter-spacing: 2px;
}
.menu-grp {
  list-style-type: none;
  display: list-item;
}
.menu-grp a {
  text-decoration: none;
  color: #495057;
  display: block;
  padding: 10px 15px;
}
.menu-grp .active{
  background-color: #d20001;
  color: #fff;
}
.menu-grp a:hover {
  background-color: #d20001;
  color: #ffffff;
  text-decoration: none;
}
.menu-grp i {
  padding-right: 5px;
}
</style>