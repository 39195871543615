<template>
  <div class="account-launch">
    <PageTitle
      icon="fa fa-money fa-lg"
      main="Lançamento Fundo Fixo"
      path="/acLaunchAdd"
    />
    <div class="form-search-launch">
      <b-row align-h="center">
        <b-col md="2" sm="12" class="my-1">
          <b-form-group label="Pesquisa" label-for="filter-input">
            <b-form-input
              size="sm"
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escreva para pesquisar"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group label="Lançamento" label-for="launch-release">
            <b-form-datepicker
              size="sm"
              placeholder="Inicio"
              v-model="paramsSearch.launchInitial"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-form-datepicker
              size="sm"
              placeholder="Fim"
              v-model="paramsSearch.launchFinal"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group label="Movimento" label-for="launch-release">
            <b-form-datepicker
              size="sm"
              placeholder="Inicio"
              v-model="paramsSearch.moveInitial"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-form-datepicker
              size="sm"
              placeholder="Fim"
              v-model="paramsSearch.moveFinal"
              today-button
              label-today-button="Hoje"
              reset-button
              label-reset-button="Limpar"
              close-button
              label-close-button="Fechar"
              locale="pt-BR"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12" class="my-1">
          <b-form-group :label="`\u00A0`">
            <b-button variant="primary" size="sm" @click="loadLaunch"
              >Pesquisar</b-button
            >
            <b-button
              class="ml-2"
              :disabled="isfiltering"
              variant="secondary"
              size="sm"
              @click="generatePdf"
              >Gerar PDF</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table
      small
      hover
      striped
      :items="launch"
      :fields="fields"
      :current-page="page"
      :per-page="limit"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      empty-text="Não há registros correspondentes à sua solicitação"
      empty-filtered-text="Não há registros correspondentes à sua solicitação"
      sort-icon-left
    >
      <template #cell(actions)="data">
        <b-button
          variant="danger"
          size="sm"
          :disabled="data.item.reversed === 'S'"
          @click="remove(data.item)"
          class="mr-2"
        >
          <i class="fa fa-undo"></i>
        </b-button>
      </template>
    </b-table>
    <b-row align-h="between">
      <b-col md="4" sm="6" class="my-1">
        <b-pagination
          class="my-1"
          v-if="count > limit"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          aria-controls="table-ac-launch"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col md="3" sm="6" class="my-1">
        <b-form-group
          label="Por página:"
          label-for="per-page-select"
          label-cols-sm="8"
          label-align-sm="right"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="limit"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import moment from "moment";
import { accountLaunch } from "../report/financialReport/accountLaunch";
export default {
  name: "account-launch",
  components: { PageTitle },
  data: function () {
    return {
      launch: [],
      paramsSearch: {},
      filter: null,
      isfiltering: false,
      page: 1,
      limit: 10,
      count: 0,
      pageOptions: [10, 20, 30],
      fields: [
        { key: "operation", label: "Operação" },
        {
          key: "dateRelease",
          label: "Lançamento",
          sortable: true,
          formatter: "formatDate",
        },
        {
          key: "dateMove",
          label: "Movimento",
          sortable: true,
          formatter: "formatDate",
        },
        {
          key: "value",
          label: "Valor",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    loadLaunch() {
      const url = `${baseApiUrl}/acLaunch`;
      axios
        .get(url, {
          params: this.paramsSearch,
        })
        .then((res) => {
          this.launch = res.data;
          this.count = res.data.length;
          this.isfiltering = false;
        })
        .catch(showError);
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length;
      this.page = 1;
    },
    generatePdf() {
      if (this.launch.length <= 0) {
        showError("Não há registros correspondentes à sua solicitação");
        return;
      }
      console.log(this.launch)
      accountLaunch(this.launch, this.paramsSearch);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    remove(launch) {
      if (
        confirm(`Tem certeza que deseja estornar o lançamento ${launch.id}?`)
      ) {
        axios
          .put(`${baseApiUrl}/acLaunch/${launch.id}`, {
            typeOperation: launch.typeOperation,
            idAccount: launch.idAccount,
            operation: launch.operation,
            value: launch.value,
          })
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadLaunch();
          })
          .catch(showError);
      }
    },
  },
  watch: {
    paramsSearch: {
      handler() {
        this.isfiltering = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.loadLaunch();
  },
};
</script>

<style>
</style>