<template>
  <div class="company-add">
    <PageTitle icon="fa fa-building fa-lg" main="Cadastro Loja" />
    <b-form class="company-form-add">
      <input v-if="id" type="hidden" id="company-id" v-model="company.id" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Razão Social" label-for="company-name">
            <b-form-input
              id="company-name"
              v-model="company.name"
              trim
              autofocus
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="60"
              :state="validateState('name')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Nome fantasia" label-for="company-fantasy">
            <b-form-input
              id="company-fantasy"
              v-model="company.fantasy"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CNPJ" label-for="company-cnpj">
            <TheMask
              id="company-cnpj"
              v-model="company.cnpj"
              mask="##.###.###/####-##"
              class="form-control"
              :class="{ 'is-invalid': validateState('cnpj') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12">
          <b-form-group label="Associado" label-for="company-associated">
            <b-form-select
              v-model="company.idAssociated"
              :options="options"
              id="company-associated"
              :state="validateState('idAssociated')"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Selecione o associado --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="E-mail" label-for="company-email">
            <b-form-input
              type="email"
              id="company-email"
              v-model="company.email"
              trim
              lazy-formatter
              autocomplete="off"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="CEP" label-for="company-zipcode">
            <TheMask
              id="company-zipcode"
              v-model="company.zipcode"
              mask="#####-###"
              class="form-control"
              :class="{ 'is-invalid': validateState('zipcode') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group label="Logradouro" label-for="company-address">
            <b-form-input
              id="company-address"
              v-model="company.address"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="45"
              :state="validateState('address')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Numero" label-for="company-number">
            <TheMask
              id="company-number"
              v-model="company.number"
              mask="######"
              class="form-control"
              :class="{ 'is-invalid': validateState('number') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Bairro" label-for="company-district">
            <b-form-input
              id="company-district"
              v-model="company.district"
              trim
              autocomplete="off"
              lazy-formatter
              :formatter="formatter"
              maxlength="20"
              :state="validateState('district')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4" sm="12">
          <b-form-group label="Complemento" label-for="company-complement">
            <b-form-input
              id="company-complement"
              v-model="company.complement"
              trim
              autocomplete="off"
              lazy-formatter
              :formatter="formatter"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Cidade" label-for="company-city">
            <b-form-input
              id="company-city"
              v-model="company.city"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="20"
              :state="validateState('city')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="12">
          <b-form-group label="UF" label-for="company-state">
            <b-form-input
              id="company-state"
              v-model="company.state"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="2"
              :state="validateState('state')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Telefone" label-for="company-phone">
            <TheMask
              id="company-phone"
              v-model="company.phone"
              :mask="['(##) ####-####', '(##) #####-####']"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button @click="reset" class="mr-2" variant="secondary"
        >Limpar</b-button
      >
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { TheMask } from "vue-the-mask";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CompanyAdd",
  props: ["id"],
  components: { PageTitle, TheMask },
  data: function () {
    return {
      company: {
        idAssociated: null,
      },
      options: [],
    };
  },
  validations: {
    company: {
      name: {
        required,
      },
      cnpj: {
        required,
      },
      idAssociated: {
        required,
      },
      zipcode: {
        required,
      },
      address: {
        required,
      },
      number: {
        required,
      },
      district: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      this.$v.company.$touch();
      if (!this.$v.company.$invalid) {
        const method = this.company.id ? "put" : "post";
        const id = this.company.id ? `/${this.company.id}` : "";
        const url = `${baseApiUrl}/company${id}`;
        axios[method](url, this.company)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.$router.push("/company");
          })
          .catch(showError);
      }
    },
    reset() {
      this.company = {};
      this.$v.company.$reset();
    },
    back() {
      this.$router.push("/company");
    },
    loadPage() {
      if (this.$route.params.id) {
        this.company.id = this.$route.params.id;
        const url = `${baseApiUrl}/company/${this.company.id}`;
        axios.get(url).then((res) => {
          this.company = res.data;
        });
      }
    },
    getAssociated() {
      const url = `${baseApiUrl}/associated`;
      axios.get(url).then((res) => {
        this.options = res.data.associated.map((associated) => {
          return {
            value: associated.id,
            text: `${associated.description}`,
          };
        });
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty && $error ? !$error : null;
    },
  },
  mounted() {
    this.getAssociated();
    this.loadPage();
  },
};
</script>

<style>
.company-form-add {
  padding: 20px;
}
</style>