import { getDateNow } from './formatterData'

export function header(title) {
   return {
      text: title,
      fontSize: 14,
      alignment: 'center',
      bold: true,
      margin: [0, 20, 0, 0],
   };
}

export const footer = function (currentPage, pageCount) {
   return {
      columns: [
         {
            text: getDateNow(),
            margin: [10, 0],
            fontSize: 8,
         },
         {
            text: `${currentPage.toString()} de ${pageCount}`,
            width: "70",
            alignment: "right",
            margin: [10, 0],
            fontSize: 8,
         },
      ]
   };
};

export const marginFilter = [0, 15, 0, 10];
export const pageA4Margins = [20, 40, 20, 30];