<template>
  <div class="operation">
    <PageTitle icon="fa fa-money fa-lg" main="Operação Fundo Fixo" path="/operationAdd" />
    <div>
      <b-row align-h="center">
        <b-col cols="6" class="my-1">
          <b-form-group
            label="Pesquisa"
            label-for="filter-input"
            label-cols-sm="2"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escreva para pesquisar"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="my-1">
          <b-form-group
            label="Por página:"
            label-for="per-page-select"
            label-cols-sm="9"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="limit"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table
      small
      hover
      striped
      :items="operation"
      :fields="fields"
      :current-page="page"
      :per-page="limit"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      empty-text="Não há registros correspondentes à sua solicitação"
      empty-filtered-text="Não há registros correspondentes à sua solicitação"
    >
      <template #cell(actions)="data">
        <b-button
          variant="warning"
          size="sm"
          @click="edit(data.item.id)"
          class="mr-2"
        >
          <i class="fa fa-pencil"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="remove(data.item)"
          class="mr-2"
        >
          <i class="fa fa-trash"></i>
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="count > limit"
      v-model="page"
      :total-rows="count"
      :per-page="limit"
      aria-controls="table-associated"
      size="sm"
    ></b-pagination>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";

export default {
  name: "Operation",
  components: { PageTitle },
  data: function () {
    return {
      operation: [],
      page: 1,
      limit: 10,
      count: 0,
      filter: null,
      pageOptions: [1, 10, 20, 30],
      fields: [
        { key: "id", label: "Código", sortable: true },
        { key: "description", label: "Nome", sortable: true },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    loadOperation() {
      const url = `${baseApiUrl}/operation`;
      axios.get(url).then((res) => {
        this.operation = res.data;
      });
    },
    remove(operation) {
      if (
        confirm(
          `Tem certeza que deseja excluir a operação ${operation.description}?`
        )
      ) {
        axios
          .delete(`${baseApiUrl}/operation/${operation.id}`)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadOperation();
          })
          .catch(showError);
      }
    },
    edit(id) {
      this.$router.push(`/operationAdd/${id}`);
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length;
      this.page = 1;
    },
  },
  mounted() {
    this.loadOperation();
  },
};
</script>

<style>
</style>