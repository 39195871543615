<template>
  <div class="proper">
    <PageTitle icon="fa fa-industry fa-lg" main="Proprio" />
    <b-form class="proper-form-add">
      <input type="hidden" id="proper-id" v-model="proper.id" />
      <b-row>
        <b-col md="5" sm="12">
          <b-form-group label="Razão Social" label-for="proper-name">
            <b-form-input
              id="proper-name"
              v-model="proper.name"
              trim
              autofocus
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="60"
              :state="validateState('name')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Nome fantasia" label-for="proper-fantasy">
            <b-form-input
              id="proper-fantasy"
              v-model="proper.fantasy"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="CNPJ" label-for="proper-cnpj">
            <TheMask
              id="proper-cnpj"
              v-model="proper.cnpj"
              mask="##.###.###/####-##"
              class="form-control"
              :class="{ 'is-invalid': validateState('cnpj') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <b-form-group label="CEP" label-for="proper-zipcode">
            <TheMask
              id="proper-zipcode"
              v-model="proper.zipcode"
              mask="#####-###"
              class="form-control"
              :class="{ 'is-invalid': validateState('zipcode') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="5" sm="12">
          <b-form-group label="Logradouro" label-for="proper-address">
            <b-form-input
              id="proper-address"
              v-model="proper.address"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="45"
              :state="validateState('address')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Numero" label-for="proper-number">
            <TheMask
              id="proper-number"
              v-model="proper.number"
              mask="######"
              class="form-control"
              :class="{ 'is-invalid': validateState('number') === false }"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12">
          <b-form-group label="Bairro" label-for="proper-district">
            <b-form-input
              id="proper-district"
              v-model="proper.district"
              trim
              autocomplete="off"
              lazy-formatter
              :formatter="formatter"
              maxlength="20"
              :state="validateState('district')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3" sm="12">
          <b-form-group label="Complemento" label-for="proper-complement">
            <b-form-input
              id="proper-complement"
              v-model="proper.complement"
              trim
              autocomplete="off"
              lazy-formatter
              :formatter="formatter"
              maxlength="30"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Cidade" label-for="proper-city">
            <b-form-input
              id="proper-city"
              v-model="proper.city"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="20"
              :state="validateState('city')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="12">
          <b-form-group label="UF" label-for="proper-state">
            <b-form-input
              id="proper-state"
              v-model="proper.state"
              trim
              lazy-formatter
              :formatter="formatter"
              autocomplete="off"
              maxlength="2"
              :state="validateState('state')"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="12">
          <b-form-group label="Telefone" label-for="proper-phone">
            <TheMask
              id="proper-phone"
              v-model="proper.phone"
              :mask="['(##) ####-####', '(##) #####-####']"
              class="form-control"
            >
            </TheMask>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Categoria Estorno Credito">
            <b-form-select
              v-model="proper.idCatRevIn"
              :options="optionsExpense"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="Categoria Estorno Debito">
            <b-form-select
              v-model="proper.idCatRevOut"
              :options="optionsRevenue"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { required } from "vuelidate/lib/validators";
import { TheMask } from "vue-the-mask";
export default {
  name: "Proper",
  components: { PageTitle, TheMask },
  data: function () {
    return {
      proper: {},
      optionsExpense: [],
      optionsRevenue: [],
    };
  },
  validations: {
    proper: {
      name: {
        required,
      },
      cnpj: {
        required,
      },
      zipcode: {
        required,
      },
      address: {
        required,
      },
      number: {
        required,
      },
      district: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    loadProper() {
      const url = `${baseApiUrl}/proper/1`;
      axios.get(url).then((res) => {
        this.proper = res.data;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.proper[name];
      return $dirty && $error ? !$error : null;
    },
    save() {
      this.$v.proper.$touch();
      if (!this.$v.proper.$invalid) {
        const method = this.proper.id ? "put" : "post";
        const id = this.proper.id ? `/${this.proper.id}` : "";
        const url = `${baseApiUrl}/proper${id}`;
        axios[method](url, this.proper)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.loadProper()
          })
          .catch(showError);
      }
    },
    getCategoryExpense() {
      const url = `${baseApiUrl}/categories-expense`;
      axios.get(url).then((res) => {
        this.optionsExpense = res.data.map((category) => {
          return {
            value: category.id,
            text: category.description,
          };
        });
      });
    },
    getCategoryRevenue() {
      const url = `${baseApiUrl}/categories-revenue`;
      axios.get(url).then((res) => {
        this.optionsRevenue = res.data.map((category) => {
          return {
            value: category.id,
            text: category.description,
          };
        });
      });
    },
  },
  mounted() {
    this.loadProper();
    this.getCategoryExpense()
    this.getCategoryRevenue()
  },
};
</script>

<style>
</style>