<template>
  <div class="dashboard">
    <PageTitle icon="fa fa-pie-chart" main="Dashboard" />
    <b-form-group class="mr-sm-2" label="Ano" label-for="filter-input">
      <b-form-select
        size="sm"
        id="provider"
        class="mb-2 mr-sm-2 mb-sm-0 ml-2"
        v-model="filter.date"
        :options="optionsYears"
      >
      </b-form-select>
    </b-form-group>
    <div class="charts" v-if="user.role">
      <ChartTarget
        class="chart"
        v-if="loaded"
        :chartdata="chartdata"
        :options="options"
      ></ChartTarget>
      <ChartPurchase
        class="chart"
        v-if="loaded"
        :chartdata="chartCompany"
        :options="options"
      >
      </ChartPurchase>
    </div>
    <div class="cab">
      <h4>Metas</h4>
      <a href="#" class="toggle" @click="toggleTargets">
        <i class="fa" :class="icon()" />
      </a>
    </div>
    <div class="charts" v-if="chartTargetCompany != null">
      <div
        v-show="isTargetsVisible"
        v-for="item of chartTargetCompany"
        :key="item.id"
      >
        <ChartTarget
          class="chart"
          v-if="loaded"
          :chartdata="item"
          :options="options"
        ></ChartTarget>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import ChartTarget from "../charts/ChartTarget";
import ChartPurchase from "../charts/ChartPurchase";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import { mapState } from "vuex";
export default {
  name: "Home",
  components: { PageTitle, ChartTarget, ChartPurchase },
  computed: mapState(["user"]),
  data: function () {
    return {
      filter: {
        date: "2024",
    },
      stat: {},
      loaded: false,
      datacollection: null,
      chartdata: {},
      chartTargetCompany: null,
      chartCompany: {},
      isTargetsVisible: true,
    optionsYears: [
      { value: "2024", text: "2024" },
      { value: "2023", text: "2023" },
      { value: "2022", text: "2022" },
      { value: "2021", text: "2021" },
    ],
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    toggleTargets() {
      this.isTargetsVisible = !this.isTargetsVisible;
    },
    icon() {
      return this.isTargetsVisible ? "fa-angle-up" : "fa-angle-down";
    },
    async getStats() {
      this.loaded = false;
      const urlTarget = `${baseApiUrl}/stats/target`;
      const urlPurchase = `${baseApiUrl}/stats/getStatPurchaseFromCompany`;
      const urlTargetDetail = `${baseApiUrl}/stats/getStatTargetFromCompany`;
      await axios
        .get(urlTarget, {
          params:{
            year: this.filter.date
          }
        })
        .then((res) => {
          this.chartdata = res.data;
        })
        .catch(showError);
      await axios
        .get(urlPurchase, {
          params:{
            year: this.filter.date
          }
        })
        .then((res) => {
          this.chartCompany = res.data;
        })
        .catch(showError);
      await axios
        .get(urlTargetDetail, {
          params:{
            year: this.filter.date
          }
        })
        .then((res) => {
          this.chartTargetCompany = res.data;
          this.loaded = true;
        })
        .catch(showError);
      this.loaded = true;
    },
    async getStateTargetCompany() {
      this.loaded = false;
      const url = `${baseApiUrl}/stats/getStatTargetFromCompany`;
      await axios
        .get(url, {
          params: {
            id: this.user.company,
            year: this.filter.date
          },
        })
        .then((res) => {
          this.chartTargetCompany = res.data;
          this.loaded = true;
        })
        .catch(showError);
    },
  },
  watch:{
    'filter.date':{
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getStats()
        }
      },
      deep: true,
    }
  },
  async mounted() {
    if (this.user.role) {
      await this.getStats();
    } else {
      await this.getStateTargetCompany();
    }
  },
};
</script>

<style>
.charts {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.chart {
  padding: 10px !important;
  max-width: 19vw;
  height: auto;
}
.cab {
  margin-top: 10px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a.toggle {
  color: #d20001;
  text-decoration: none;
  padding: 5px 0;
}
</style>