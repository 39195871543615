<template>
  <div>
    <PageTitle icon="fa fa-user-plus fa-lg" main="Cadastro Usuarios" />
    <b-form class="user-form-add">
      <input v-if="id" type="hidden" id="user-id" v-model="user.id" />
      <b-row>
        <b-col md="4" sm="12">
          <b-form-group label="Nome" label-for="user-name">
            <b-form-input
              id="user-name"
              v-model="user.name"
              trim
              autofocus
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="E-mail" label-for="user-email">
            <b-form-input
              id="user-email"
              v-model="user.email"
              type="email"
              trim
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="12">
          <b-form-group label="  " class="mt-4">
            <b-form-checkbox
              id="user-role"
              v-model="user.role"
              value="1"
              unchecked-value="0"
              >Administrador</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3" sm="12" v-show="!id">
          <b-form-group label="Senha" label-for="user-password">
            <b-form-input
              id="user-password"
              v-model="user.password"
              type="password"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="12" v-if="!id">
          <b-form-group
            label="Confirmar senha"
            label-for="user-confirmPassword"
          >
            <b-form-input
              id="user-confirmPassword"
              v-model="user.confirmPassword"
              type="password"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12">
          <b-form-group label="Associado" label-for="user-company">
            <b-form-select
              v-model="user.idCompany"
              :options="options"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" class="mr-2" @click="save">Salvar</b-button>
      <b-button variant="secondary" class="mr-2" @click="reset">Limpar</b-button>
      <b-button variant="danger" @click="back">Cancelar</b-button>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
const maskCompany = (provider) => {
  return provider.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};
export default {
  name: "UserAdd",
  props: ["id"],
  components: { PageTitle },
  data: function () {
    return {
      user: {},
      options: [
         {value: null, text: 'Por favor selecione a opção'}
      ],
    };
  },
  methods: {
    formatter: (value) => {
      return value.toUpperCase();
    },
    save() {
      const method = this.user.id ? "put" : "post";
      const id = this.user.id ? `/${this.user.id}` : "";
      const url = `${baseApiUrl}/user${id}`;
      axios[method](url, this.user)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.$router.push("/user");
        })
        .catch(showError);
    },
    reset() {
      this.user = {};
    },
    back() {
      this.$router.push("/user");
    },
  },
  created() {
    const url = `${baseApiUrl}/company`;
    axios.get(url).then((res) => {
      this.options = res.data.companies.map((company) => {
        company.cnpj = maskCompany(company.cnpj);
        return {
          value: company.id,
          text: `${company.name} - ${company.cnpj}`,
        };
      });
    });  
  },
  mounted() {
    if (this.$route.params.id) {
      this.user.id = this.$route.params.id;
      const url = `${baseApiUrl}/user/${this.user.id}`;
      axios.get(url).then((res) => {
        this.user = res.data;
      });
    }
  },
};
</script>

<style>
.user-form-add {
  padding: 20px;
}
</style>