<template>
  <div class="accordion" role="tablist">
    <b-card border-variant="danger" no-body class="mb-1 card-monit">
      <b-card-header
        header-bg-variant="danger"
        header-tag="header"
        class="p-1"
        role="tab"
      >
        <div class="header-detail">
          <span>{{ data.description }}</span>
          <span>{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(data.amount)
          }}</span>
          <span>{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(data.confirmed)
          }}</span>
          <span>{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(data.amount - data.confirmed)
          }}</span>
          <b-button @click="info()" variant="warning" size="sm" class="mr-sm-1"
            ><i class="fa fa-eye"></i
          ></b-button>
          <b-button variant="warning" size="sm" @click="visible = !visible">
            <i v-if="visible" class="fa fa-chevron-up"></i>
            <i v-if="!visible" class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </b-card-header>
      <b-collapse
        :id="data.idTarget"
        v-model="visible"
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body class="small-pad">
          <div class="empty-result" v-if="data.confirmeds.length == 0">
            <span>Sem lançamentos para mostrar</span>
          </div>
          <b-table
            small
            responsive="sm"
            v-if="data.confirmeds.length > 0"
            striped
            hover
            :items="this.data.confirmeds"
            :fields="fields"
          >
            <template #cell(reference)="data">
              {{ `${data.item.referenceMonth}/${data.item.referenceYear}` }}
            </template>
            <template #cell(remaining)="data">
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(calcrest(data.item.amount, data.item.revenues))
              }}
            </template>
            <template #cell(actions)="data">
              <b-button
                :disabled="data.item.idReceive != null"
                @click="invoice(data.item)"
                variant="danger"
                size="sm"
                class="mr-sm-1"
                ><i class="fa fa-dollar"></i
              ></b-button>
            </template>
          </b-table>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "monitoring-detail",
  props: ["data"],
  data() {
    return {
      visible: false,
      fields: [
        { key: "reference", label: "Mes Referencia" },
        {
          key: "amount",
          label: "Meta",
          formatter: (value) => {
            return parseFloat(value / 12).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          key: "revenues",
          label: "Lançado",
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        { key: "remaining", label: "Falta" },
        {
          key: "valueReturn",
          label: "Retorno",
          formatter: (value) => {
            return parseFloat(value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        { key: "actions", label: "Gerar" },
      ],
    };
  },
  methods: {
    async info() {
      await this.$emit("selectTarget", this.data.id);
      this.$bvModal.show("modal-dt-target");
    },
    async invoice(target) {
      await this.$emit("selectTarget", this.data.id);
      await this.$emit("selectBilling", target);
      this.$bvModal.show("modal-bg-target");
    },
    calcrest(target, released) {
      if (target / 12 - released > 0) {
        return target / 12 - released;
      }
      return 0;
    },
  },
  computed: {
    getValueAnual() {
      const result = this.data.revenues * (this.data.commission / 100);
      return result;
    },
  },
};
</script>

<style>
.obs {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

.card-monitoring button {
  color: #fff;
}
.grp-actions .btn {
  padding: 0px 0.5rem;
  min-width: 35px;
}
.grp-actions .btn:hover {
  border: 1px solid white;
  border-radius: 5px;
}
.grp-actions {
  text-align: end;
}

.header-detail {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-weight: 600;
}

.header-detail span {
  flex: 1;
}
.body-detail {
  display: flex;
  justify-content: space-between;
}

.body-detail span {
  flex: 1;
}

.empty-result {
  text-align: center !important;
  background-color: rgba(0, 0, 0, 0.05);
}
.small-pad {
  padding: 0.5rem;
}
</style>