<template>
  <footer class="footer"><i class="fa fa-copyright" aria-hidden="true"></i>Rede Construmap</footer>
</template>

<script>
export default {
   name: 'Footer'
}
</script>

<style>
   .footer{
      grid-area: footer;
      background-color: #ddd;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px;
   }
</style>